export const backgroundColor = "#1D222B";
export const primaryColor = "#930023";
export const sidebarColor = "#0B111D";
export const borderColor = "#515763";
export const paperColor = "#272F3A";
export const inputColor = "#14181E";
export const textColor = "#5E6D7F";
export const errorColor = "#E40914";
export const successColor = "#3AAA35";
export const whiteColor = "#FFFFFF";
export const blackColor = "#000000";
