import { createTheme } from "@mui/material/styles";
import {
  backgroundColor,
  errorColor,
  primaryColor,
  successColor,
} from "../../assets/colors";
// --------------------------------------------------------------------------------
// NOTE Create Custom Theme
export const theme = createTheme({
  direction: "rtl",
  palette: {
    mode: "dark",
    background: {
      paper: backgroundColor,
      default: backgroundColor,
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: "#F0F0F0",
    },
    error: {
      main: errorColor,
    },
    success: {
      main: successColor,
    },
  },
  typography: {
    fontFamily: ["IRANYekan-Regular"].join(","),
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
        },
      },
    },
  },
});
