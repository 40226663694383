import styled from "@emotion/styled";
import MuiTableCell, { tableCellClasses } from "@mui/material/TableCell";

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: theme.palette.common.black,
    //   color: theme.palette.common.white,
    borderBottom: "5px solid #495663",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  "&:nth-of-type(odd)": {
    borderBottom: "5px solid #5E6D7F",
  },
}));

export default TableCell;
