import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MuiListItemButton from "@mui/material/ListItemButton";
import MuiListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { AvatarIcon, YektuberIcon } from "../../../assets/SVGs";
import { sidebarColor } from "../../../assets/colors";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MailIcon from "@mui/icons-material/Mail";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button as MuiButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Cropper from "react-easy-crop";
import {
  CHANGE_PROFILE_PICTURE_STARTED,
  CHANGE_PROFILE_PICTURE_SUCCESS,
} from "../../../redux/types";
import Button from "../../Button";
import { changeProfilePictureRequest } from "../../../redux/actions/Profile";
import { getCroppedImg } from "../../../configs/canvasUtils";
import { getTicketBadgeRequest } from "../../../redux/actions";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
// --------------------------------------------------------------------------------
function ResponsiveDrawer(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const { firstName, lastName, profileSrc, emailStatus, mobileStatus } =
    useSelector((state) => state.authReducer);
  const { rType } = useSelector((state) => state.profileReducer);
  const { ticketBadge } = useSelector((state) => state.ticketReducer);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const { window } = props;
  const loading = rType === CHANGE_PROFILE_PICTURE_STARTED;

  useEffect(() => {
    dispatch(getTicketBadgeRequest());
  }, []);

  useEffect(() => {
    switch (rType) {
      case CHANGE_PROFILE_PICTURE_SUCCESS:
        setShowProfileModal(false);
        break;
      default:
        break;
    }
  }, [rType]);

  const handleChangePage = (path) => {
    !isDesktop && props.handleDrawerToggle();
    navigate(path);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setImageSrc(imageDataUrl);
    }
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);

      setImageSrc(null);
      dispatch(changeProfilePictureRequest({ file: croppedImage }));
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);

  const renderProfilePictureModal = () => {
    return (
      <Dialog
        open={showProfileModal}
        onClose={() => setShowProfileModal(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ textAlign: "center" }}>
          پروفایل
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <Grid
            sx={{ position: "relative", height: imageSrc && "20rem" }}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              {imageSrc ? (
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={5 / 5}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropShape="round"
                />
              ) : (
                <Avatar
                  src={profileSrc}
                  sx={{ width: 84, height: 84, m: 5 }}
                />
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <MuiButton variant="contained" component="label" sx={{ m: 2 }}>
              انتخاب عکس
              <input
                type="file"
                onChange={onFileChange}
                accept="image/*"
                hidden
              />
            </MuiButton>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            text="ذخیره"
            onClick={showCroppedImage}
            loading={loading}
            disabled={!imageSrc}
          />
          <Button
            text="لغو"
            onClick={() => {
              setImageSrc(null);
              setShowProfileModal(false);
            }}
            color="secondary"
            variant="outlined"
          />
        </DialogActions>
      </Dialog>
    );
  };

  const drawer = (
    <div>
      <Toolbar />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box>
          <IconButton
            onClick={() => setShowProfileModal(true)}
            disabled={emailStatus !== "Active" || mobileStatus !== "Active"}
          >
            {profileSrc ? (
              <Avatar src={profileSrc} sx={{ width: 84, height: 84 }} />
            ) : (
              <AvatarIcon />
            )}
          </IconButton>
        </Box>
        <Typography sx={{ py: 2 }}>{`${firstName} ${lastName}`}</Typography>
      </Box>
      <Divider variant="middle" />
      <List sx={{ marginY: 3 }}>
        <ListItem>
          <ListItemButton
            onClick={() => handleChangePage("/my-channels")}
            selected={location?.pathname === "/my-channels"}
            disableRipple
            disableGutters
          >
            <ListItemIcon sx={{ justifyContent: "center", color: "unset" }}>
              <LiveTvIcon />
            </ListItemIcon>
            <ListItemText primary="کانال‌های من" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => handleChangePage("/income")}
            selected={location?.pathname === "/income"}
            disableRipple
            disableGutters
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <PaidOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="درآمد‌ها" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => handleChangePage("/profile")}
            selected={location?.pathname === "/profile"}
            disableRipple
            disableGutters
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <AccountCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="پروفایل کاربری" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => handleChangePage("/settings")}
            selected={location?.pathname === "/settings"}
            disableRipple
            disableGutters
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="تنظیمات" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => handleChangePage("/tickets")}
            selected={location?.pathname === "/tickets"}
            disableRipple
            disableGutters
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <Badge badgeContent={ticketBadge} color="error">
                <MailIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="تیکت‌ها" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => handleChangePage("/cooperation")}
            selected={location?.pathname === "/cooperation"}
            disableRipple
            disableGutters
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <Badge badgeContent={ticketBadge} color="error">
                <Groups2OutlinedIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="همکاری در بازاریابی" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { lg: props.drawerWidth }, flexShrink: { lg: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: props.drawerWidth,
          },
        }}
        PaperProps={{
          sx: {
            backgroundColor: sidebarColor,
            backgroundImage: "none",
          },
        }}
      >
        {drawer}
        <YektuberIcon style={{ width: 117, margin: "auto" }} />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: props.drawerWidth,
          },
        }}
        open
        PaperProps={{
          sx: {
            backgroundColor: sidebarColor,
          },
        }}
      >
        {drawer}
        <YektuberIcon style={{ width: 117, margin: "auto" }} />
      </Drawer>
      {renderProfilePictureModal()}
    </Box>
  );
}

export default ResponsiveDrawer;

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "unset",
    color: theme.palette.error.main,
    ":hover": {
      backgroundColor: "unset",
    },
  },
}));

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  color: "unset",
}));

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
