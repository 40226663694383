import { put, takeLatest } from "redux-saga/effects";
import {
  CREATE_REPLY_TICKET_REQUEST,
  CREATE_TICKET_REQUEST,
  GET_ALL_TICKETS_REQUEST,
  GET_TICKET_BADGE_REQUEST,
  GET_TICKET_DETAILS_REQUEST,
} from "../types";
import {
  createReplyTicketAPI,
  createTicketAPI,
  getAllTicketsAPI,
  getTicketBadgeAPI,
  getTicketDetailsAPI,
} from "../../services/API/Ticket";
import {
  createReplyTicketFailure,
  createReplyTicketStarted,
  createReplyTicketSuccess,
  createTicketFailure,
  createTicketStarted,
  createTicketSuccess,
  getAllTicketsFailure,
  getAllTicketsRequest,
  getAllTicketsStarted,
  getAllTicketsSuccess,
  getTicketBadgeFailure,
  getTicketBadgeStarted,
  getTicketBadgeSuccess,
  getTicketDetailsFailure,
  getTicketDetailsRequest,
  getTicketDetailsStarted,
  getTicketDetailsSuccess,
} from "../actions/Ticket";
// --------------------------------------------------------------------------------
function* onCreateTicket({ payload }) {
  yield put(createTicketStarted());
  try {
    let response = yield createTicketAPI(payload);
    if (response.status === "success") {
      yield put(createTicketSuccess());
      yield put(getAllTicketsRequest());
    } else {
      yield put(createTicketFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(createTicketFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetAllTickets({ payload }) {
  yield put(getAllTicketsStarted());
  try {
    let response = yield getAllTicketsAPI(payload);
    if (response.status === "success") {
      yield put(getAllTicketsSuccess({ tickets: response.data }));
    } else {
      yield put(getAllTicketsFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getAllTicketsFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetTicketDetails({ payload }) {
  yield put(getTicketDetailsStarted());
  try {
    let response = yield getTicketDetailsAPI(payload);
    if (response.status === "success") {
      yield put(getTicketDetailsSuccess({ selectedTicket: response.data }));
    } else {
      yield put(getTicketDetailsFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getTicketDetailsFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onCreateReplyTicket({ payload }) {
  yield put(createReplyTicketStarted());
  try {
    let response = yield createReplyTicketAPI(payload);
    if (response.status === "success") {
      let ticket_id = payload.get("ticket_id");
      yield put(createReplyTicketSuccess());
      yield put(getTicketDetailsRequest({ ticket_id }));
    } else {
      yield put(createReplyTicketFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(createReplyTicketFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetTicketBadge({ payload }) {
  yield put(getTicketBadgeStarted());
  try {
    let response = yield getTicketBadgeAPI(payload);
    if (response.status === "success") {
      yield put(getTicketBadgeSuccess({ ticketBadge: response.data }));
    } else {
      yield put(getTicketBadgeFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getTicketBadgeFailure({ error: error.message }));
  }
}
// ================================================================================
function* ticketSaga() {
  yield takeLatest(CREATE_TICKET_REQUEST, onCreateTicket);
  yield takeLatest(GET_ALL_TICKETS_REQUEST, onGetAllTickets);
  yield takeLatest(GET_TICKET_DETAILS_REQUEST, onGetTicketDetails);
  yield takeLatest(CREATE_REPLY_TICKET_REQUEST, onCreateReplyTicket);
  yield takeLatest(GET_TICKET_BADGE_REQUEST, onGetTicketBadge);
}

export default ticketSaga;
