import { useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { paperColor } from "../../../../assets/colors";
import Accordion from "../../../../components/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { getDateByLocale } from "../../../../utils/helpers";
import { seenAnnouncementRequest } from "../../../../redux/actions/Profile";
import { DialogIcon } from "../../../../assets/SVGs";
// --------------------------------------------------------------------------------
const Announcements = () => {
  const dispatch = useDispatch();
  const { announcements } = useSelector((state) => state.profileReducer);

  // useEffect(() => {
  //   let announcement_id = notifications.map((ann) => ann.id);
  //   dispatch(seenAnnouncementRequest({ announcement_id }));
  // }, []);

  return (
    <Box>
      <Grid container sx={{ my: 10 }}>
        <Grid item sx={{ m: " 20px auto" }}>
          <Typography variant="h4" textAlign="center">
            اطلاعیه‌ها
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ mb: 10 }}>
        {announcements.length > 0 ? (
          <Grid
            item
            sx={{ backgroundColor: paperColor, p: 3, borderRadius: 2, flex: 1 }}
          >
            {announcements.map(
              ({ id, title_fa, description_fa, created_at, seen }) => (
                <Accordion
                  summary={
                    <>
                      <Box
                        sx={{ display: "flex", alignItems: "center", flex: 1 }}
                      >
                        <DialogIcon style={{ width: 44.24, marginLeft: 20 }} />
                        <Typography sx={{ flex: 1 }}>{title_fa}</Typography>
                      </Box>
                      <Typography
                        sx={{ mr: 2, direction: "rtl" }}
                        variant="overline"
                      >
                        {getDateByLocale(created_at)}
                      </Typography>
                    </>
                  }
                  desc={description_fa}
                  onClick={() =>
                    !seen &&
                    dispatch(seenAnnouncementRequest({ announcement_id: id }))
                  }
                />
              )
            )}
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default Announcements;
