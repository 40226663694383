import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./routes/Login";
import Register from "./routes/Register";
import ForgotPassword from "./routes/ForgotPassword";
import { useEffect } from "react";
import { getBootRequest } from "../../redux/actions";
import { useDispatch } from "react-redux";
// --------------------------------------------------------------------------------
const Auth = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBootRequest({ secret: "AppYek**175GHD@#" }));
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/login/:email" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/register/:referralCode" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default Auth;
