import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_SETTINGS_FAILURE,
  CHANGE_SETTINGS_REQUEST,
  CHANGE_SETTINGS_STARTED,
  CHANGE_SETTINGS_SUCCESS,
  GET_SETTINGS_REPORT_FAILURE,
  GET_SETTINGS_REPORT_REQUEST,
  GET_SETTINGS_REPORT_STARTED,
  GET_SETTINGS_REPORT_SUCCESS,
} from "../types";
// --------------------------------------------------------------------------------
export const changeSettingsRequest = (data) => ({
  type: CHANGE_SETTINGS_REQUEST,
  payload: data,
});

export const changeSettingsStarted = () => ({
  type: CHANGE_SETTINGS_STARTED,
});

export const changeSettingsSuccess = (data) => ({
  type: CHANGE_SETTINGS_SUCCESS,
  payload: data,
});

export const changeSettingsFailure = (data) => ({
  type: CHANGE_SETTINGS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getSettingsReportRequest = () => ({
  type: GET_SETTINGS_REPORT_REQUEST,
});

export const getSettingsReportStarted = () => ({
  type: GET_SETTINGS_REPORT_STARTED,
});

export const getSettingsReportSuccess = (data) => ({
  type: GET_SETTINGS_REPORT_SUCCESS,
  payload: data,
});

export const getSettingsReportFailure = (data) => ({
  type: GET_SETTINGS_REPORT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changePasswordRequest = (data) => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload: data,
});

export const changePasswordStarted = () => ({
  type: CHANGE_PASSWORD_STARTED,
});

export const changePasswordSuccess = (data) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: data,
});

export const changePasswordFailure = (data) => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload: data,
});
