import {
  CHANGE_PROFILE_PICTURE_SUCCESS,
  FORGOT_EMAIL_FAILURE,
  FORGOT_EMAIL_STARTED,
  FORGOT_EMAIL_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_STARTED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_TOKEN_FAILURE,
  FORGOT_TOKEN_STARTED,
  FORGOT_TOKEN_SUCCESS,
  GET_BOOT_FAILURE,
  GET_BOOT_STARTED,
  GET_BOOT_SUCCESS,
  LOGIN_USER_DEFAULT,
  LOGIN_USER_FAILURE,
  LOGIN_USER_STARTED,
  LOGIN_USER_SUCCESS,
  LOGOUT_ADMIN_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_STARTED,
  REGISTER_USER_SUCCESS,
  SEND_MOBILE_OTP_FAILURE,
  SEND_MOBILE_OTP_STARTED,
  SEND_MOBILE_OTP_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  VERIFY_EMAIL_STARTED,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_MOBILE_FAILURE,
  VERIFY_MOBILE_STARTED,
  VERIFY_MOBILE_SUCCESS,
  VERIFY_USER_FAILURE,
  VERIFY_USER_STARTED,
  VERIFY_USER_SUCCESS,
} from "../types";
// --------------------------------------------------------------------------------
const initialState = {
  rType: LOGIN_USER_DEFAULT,
  authToken: "",
  otp: false,
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER_STARTED:
    case LOGIN_USER_STARTED:
    case VERIFY_USER_STARTED:
    case VERIFY_EMAIL_STARTED:
    case FORGOT_EMAIL_STARTED:
    case FORGOT_TOKEN_STARTED:
    case FORGOT_PASSWORD_STARTED:
    case SEND_MOBILE_OTP_STARTED:
    case VERIFY_MOBILE_STARTED:
    case GET_BOOT_STARTED:
      return {
        ...state,
        rType: action.type,
      };
    case LOGOUT_ADMIN_SUCCESS:
      return {
        ...initialState,
        rType: LOGOUT_ADMIN_SUCCESS,
        error: "",
      };
    case REGISTER_USER_SUCCESS:
    case LOGIN_USER_SUCCESS:
    case VERIFY_USER_SUCCESS:
    case VERIFY_EMAIL_SUCCESS:
    case FORGOT_EMAIL_SUCCESS:
    case FORGOT_TOKEN_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
    case SEND_MOBILE_OTP_SUCCESS:
    case VERIFY_MOBILE_SUCCESS:
    case GET_BOOT_SUCCESS:
    case CHANGE_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
        error: "",
      };
    case REGISTER_USER_FAILURE:
    case LOGIN_USER_FAILURE:
    case VERIFY_USER_FAILURE:
    case VERIFY_EMAIL_FAILURE:
    case FORGOT_EMAIL_FAILURE:
    case FORGOT_TOKEN_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
    case SEND_MOBILE_OTP_FAILURE:
    case VERIFY_MOBILE_FAILURE:
    case GET_BOOT_FAILURE:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
      };
    default:
      return state;
  }
};
