import { Box, Grid, Link, Typography } from "@mui/material";
import FormGenerator from "../../../../components/FormGenerator";
import { formItems } from "./formItems";
import { EmailVerifyIcon } from "../../../../assets/SVGs";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmailRequest } from "../../../../redux/actions/Auth";
import { VERIFY_EMAIL_STARTED } from "../../../../redux/types";
// --------------------------------------------------------------------------------
const MobileVerify = () => {
  const dispatch = useDispatch();
  const { rType } = useSelector((state) => state.authReducer);

  const loading = rType === VERIFY_EMAIL_STARTED;

  return (
    <Grid container sx={{ justifyContent: "center" }}>
      <Box textAlign="center" sx={{ mb: 5 }}>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          تایید آدرس ایمیل
        </Typography>
        <Typography>
          کد 5 رقمی ارسال شده به آدرس ایمیل خود را وارد نمایید.
        </Typography>
      </Box>
      <FormGenerator
        formItems={formItems}
        onSubmit={(values) => dispatch(verifyEmailRequest(values))}
        btnText="ثبت"
        loading={loading}
      />
      <Grid item xs={10}>
        <EmailVerifyIcon />
      </Grid>
    </Grid>
  );
};

export default MobileVerify;
