import { Box, Grid, Typography } from "@mui/material";
import { borderColor } from "../../../../assets/colors";
import Card from "../../../../components/Card";

const Contract = () => {
  return (
    <>
      <Box
        paddingY={5}
        marginBottom={3}
        borderBottom={`1px solid ${borderColor}`}
      >
        <Typography variant="h4" textAlign="center">
          قرارداد
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item>
          <Card />
        </Grid>
        <Grid item>
          <Card />
        </Grid>
        <Grid item>
          <Card />
        </Grid>
        <Grid item>
          <Card />
        </Grid>
      </Grid>
    </>
  );
};

export default Contract;
