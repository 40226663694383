import { CircularProgress } from "@mui/material";
import MuiButton from "@mui/material/Button";
// --------------------------------------------------------------------------------
const Button = ({
  text,
  variant = "contained",
  color = "primary",
  onClick,
  loading,
  ...otherProps
}) => {
  return (
    <MuiButton variant={variant} color={color} onClick={onClick} fullWidth {...otherProps}>
      {loading ? <CircularProgress color="secondary" size={25} /> : text}
    </MuiButton>
  );
};

export default Button;
