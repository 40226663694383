import axiosMiddleware from ".";
// --------------------------------------------------------------------------------
export const createTicketAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/create",
    body: data,
  });
export const getAllTicketsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/all",
    body: data,
  });
export const getTicketDetailsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/show",
    body: data,
  });
export const createReplyTicketAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "ticket/reply",
    body: data,
  });
export const getTicketBadgeAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "get-ticket-badge",
    body: data,
  });
