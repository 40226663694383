import { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { borderColor } from "../../../../assets/colors";
import Table from "../../../../components/Table";
import FormGenerator from "../../../../components/FormGenerator";
import { formItems } from "./formItems";
import {
  createTicketRequest,
  getAllTicketsRequest,
} from "../../../../redux/actions/Ticket";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_TICKET_STARTED } from "../../../../redux/types";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";
// --------------------------------------------------------------------------------
const Tickets = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { rType, tickets } = useSelector((state) => state.ticketReducer);
  const loading = rType === CREATE_TICKET_STARTED;

  let columns = [
    { field: "id", headerName: "شماره تیکت" },
    { field: "subject", headerName: "عنوان" },
    { field: "created_at", headerName: " تاریخ" },
    { field: "updated_at", headerName: "آخرین بروزرسانی" },
    {
      field: "status",
      headerName: "وضعیت",
      renderCell: ({ status }) => {
        switch (status) {
          case "Admin Reply":
            return "پاسخ داده شده";
          case "Close":
            return "بسته شده";
          default:
            return "منتظر پاسخ شما";
        }
      },
    },
    {
      renderCell: ({ id }) => (
        <Button text="مشاهده" onClick={() => Navigate(`ticket/${id}`)} />
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAllTicketsRequest());
  }, []);

  return (
    <Box>
      <Box paddingY={5} marginBottom={3}>
        <Typography variant="h4" textAlign="center">
          تیکت
        </Typography>
      </Box>
      <Box sx={{ borderBottom: `1px solid ${borderColor}`, mb: 5 }}>
        <Typography gutterBottom>ارسال تیکت جدید</Typography>
      </Box>
      <FormGenerator
        formItems={formItems}
        onSubmit={({ subject, text, attachment }, clearForm) => {
          let formData = new FormData();

          formData.append("subject", subject);
          formData.append("text", text);
          formData.append("category_id", 26);
          formData.append("source", "Website");
          if (attachment) formData.append("attachment", attachment);

          dispatch(createTicketRequest(formData));
          clearForm();
        }}
        loading={loading}
      />
      <Box sx={{ borderBottom: `1px solid ${borderColor}`, mb: 5 }}>
        <Typography gutterBottom>لیست تیکت‌ها</Typography>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Table columns={columns} data={tickets} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Tickets;
