import {
  FORGOT_EMAIL_FAILURE,
  FORGOT_EMAIL_REQUEST,
  FORGOT_EMAIL_STARTED,
  FORGOT_EMAIL_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_STARTED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_TOKEN_FAILURE,
  FORGOT_TOKEN_REQUEST,
  FORGOT_TOKEN_STARTED,
  FORGOT_TOKEN_SUCCESS,
  GET_BOOT_FAILURE,
  GET_BOOT_REQUEST,
  GET_BOOT_STARTED,
  GET_BOOT_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_STARTED,
  LOGIN_USER_SUCCESS,
  LOGOUT_ADMIN_REQUEST,
  LOGOUT_ADMIN_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_STARTED,
  REGISTER_USER_SUCCESS,
  SEND_EMAIL_TOKEN_FAILURE,
  SEND_EMAIL_TOKEN_REQUEST,
  SEND_EMAIL_TOKEN_STARTED,
  SEND_EMAIL_TOKEN_SUCCESS,
  SEND_MOBILE_OTP_FAILURE,
  SEND_MOBILE_OTP_REQUEST,
  SEND_MOBILE_OTP_STARTED,
  SEND_MOBILE_OTP_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_STARTED,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_MOBILE_FAILURE,
  VERIFY_MOBILE_REQUEST,
  VERIFY_MOBILE_STARTED,
  VERIFY_MOBILE_SUCCESS,
  VERIFY_USER_FAILURE,
  VERIFY_USER_REQUEST,
  VERIFY_USER_STARTED,
  VERIFY_USER_SUCCESS,
} from "../types";
// --------------------------------------------------------------------------------
export const registerUserRequest = (data) => ({
  type: REGISTER_USER_REQUEST,
  payload: data,
});

export const registerUserStarted = () => ({
  type: REGISTER_USER_STARTED,
});

export const registerUserSuccess = (data) => ({
  type: REGISTER_USER_SUCCESS,
  payload: data,
});

export const registerUserFailure = (data) => ({
  type: REGISTER_USER_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const loginUserRequest = (data) => ({
  type: LOGIN_USER_REQUEST,
  payload: data,
});

export const loginUserStarted = () => ({
  type: LOGIN_USER_STARTED,
});

export const loginUserSuccess = (data) => ({
  type: LOGIN_USER_SUCCESS,
  payload: data,
});

export const loginUserFailure = (data) => ({
  type: LOGIN_USER_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const verifyUserRequest = (data) => ({
  type: VERIFY_USER_REQUEST,
  payload: data,
});

export const verifyUserStarted = () => ({
  type: VERIFY_USER_STARTED,
});

export const verifyUserSuccess = (data) => ({
  type: VERIFY_USER_SUCCESS,
  payload: data,
});

export const verifyUserFailure = (data) => ({
  type: VERIFY_USER_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const sendEmailTokenRequest = (data) => ({
  type: SEND_EMAIL_TOKEN_REQUEST,
  payload: data,
});

export const sendEmailTokenStarted = () => ({
  type: SEND_EMAIL_TOKEN_STARTED,
});

export const sendEmailTokenSuccess = (data) => ({
  type: SEND_EMAIL_TOKEN_SUCCESS,
  payload: data,
});

export const sendEmailTokenFailure = (data) => ({
  type: SEND_EMAIL_TOKEN_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const verifyEmailRequest = (data) => ({
  type: VERIFY_EMAIL_REQUEST,
  payload: data,
});

export const verifyEmailStarted = () => ({
  type: VERIFY_EMAIL_STARTED,
});

export const verifyEmailSuccess = (data) => ({
  type: VERIFY_EMAIL_SUCCESS,
  payload: data,
});

export const verifyEmailFailure = (data) => ({
  type: VERIFY_EMAIL_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const verifyMobileRequest = (data) => ({
  type: VERIFY_MOBILE_REQUEST,
  payload: data,
});

export const verifyMobileStarted = () => ({
  type: VERIFY_MOBILE_STARTED,
});

export const verifyMobileSuccess = (data) => ({
  type: VERIFY_MOBILE_SUCCESS,
  payload: data,
});

export const verifyMobileFailure = (data) => ({
  type: VERIFY_MOBILE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const sendMobileOtpRequest = (data) => ({
  type: SEND_MOBILE_OTP_REQUEST,
  payload: data,
});

export const sendMobileOtpStarted = () => ({
  type: SEND_MOBILE_OTP_STARTED,
});

export const sendMobileOtpSuccess = (data) => ({
  type: SEND_MOBILE_OTP_SUCCESS,
  payload: data,
});

export const sendMobileOtpFailure = (data) => ({
  type: SEND_MOBILE_OTP_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const forgotEmailRequest = (data) => ({
  type: FORGOT_EMAIL_REQUEST,
  payload: data,
});

export const forgotEmailStarted = () => ({
  type: FORGOT_EMAIL_STARTED,
});

export const forgotEmailSuccess = () => ({
  type: FORGOT_EMAIL_SUCCESS,
});

export const forgotEmailFailure = (data) => ({
  type: FORGOT_EMAIL_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const forgotTokenRequest = (data) => ({
  type: FORGOT_TOKEN_REQUEST,
  payload: data,
});

export const forgotTokenStarted = () => ({
  type: FORGOT_TOKEN_STARTED,
});

export const forgotTokenSuccess = () => ({
  type: FORGOT_TOKEN_SUCCESS,
});

export const forgotTokenFailure = (data) => ({
  type: FORGOT_TOKEN_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const forgotPasswordRequest = (data) => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload: data,
});

export const forgotPasswordStarted = () => ({
  type: FORGOT_PASSWORD_STARTED,
});

export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
});

export const forgotPasswordFailure = (data) => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const logoutAdminRequest = () => ({
  type: LOGOUT_ADMIN_REQUEST,
});

export const logoutAdminSuccess = (data) => ({
  type: LOGOUT_ADMIN_SUCCESS,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getBootRequest = (data) => ({
  type: GET_BOOT_REQUEST,
  payload: data,
});

export const getBootStarted = () => ({
  type: GET_BOOT_STARTED,
});

export const getBootSuccess = (data) => ({
  type: GET_BOOT_SUCCESS,
  payload: data,
});

export const getBootFailure = (data) => ({
  type: GET_BOOT_FAILURE,
  payload: data,
});
