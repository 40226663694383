import axios from "axios";
import { store } from "../../redux/store";
import { openErrorModalRequest } from "../../redux/actions/Modal";
import { logoutAdminRequest } from "../../redux/actions";
import { baseAPIAddress } from "../../constants";
// --------------------------------------------------------------------------------
const axiosMiddleware = async ({ method, path, body, config }) => {
  let accessToken = store.getState().authReducer.authToken;
  let theAxios = axios.create({
    baseURL: `${baseAPIAddress}/api`,
    // timeout: 10000,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...config,
    },
  });
  let request = null;
  switch (method) {
    case "get":
      request = theAxios.get(path);
      break;
    case "post":
      request = theAxios.post(path, body);
      break;
    case "put":
      request = theAxios.put(path, body);
      break;
    case "patch":
      request = theAxios.patch(path, body);
      break;
    case "delete":
      request = theAxios.delete(path);
      break;
  }
  return request
    ?.then((response) => {
      console.warn("middleware response", { path, response });
      return response.data;
    })
    .catch(async (error) => {
      console.warn("middleware error", { path, error });
      if (error.response.status === 401) {
        store.dispatch(logoutAdminRequest());
      }
      let errorCode = error.response.data.code;
      let errors = store.getState().authReducer.boot.errors;
      let errorText = errors.find((error) => error?.code === errorCode);
      store.dispatch(openErrorModalRequest({ desc: errorText.fa }));
      if (
        "response" in error &&
        error.response &&
        "data" in error.response &&
        error.response.data &&
        "data" in error.response.data &&
        error.response.data.data
      ) {
        let errorMessage = "";
        if (typeof error.response.data.data === "string") {
          errorMessage = error.response.data.data;
        } else {
          for (let prop in error.response.data.data) {
            errorMessage +=
              prop + ": " + error.response.data.data[prop] + " - ";
          }
        }
        return { ...error.response.data, errorMessage };
      }
      return Promise.reject(error);
    });
};

export default axiosMiddleware;
