import { Avatar } from "@mui/material";
import { ChannelIcon } from "../../../../assets/SVGs";

export const formItems = (channel) => [
  {
    container: {
      item: true,
      xs: 12,
      md: 4,
      container: true,
    },
    items: [
      {
        name: "channel_name",
        type: "text",
        label: "نام کانال",
        value: channel?.channel_name,
        disabled: true,
      },
      {
        name: "channel_link",
        type: "text",
        label: "آدرس کانال",
        value: channel?.channel_link,
        disabled: true,
      },
      {
        name: "channel_content",
        type: "text",
        label: "موضوع کانال",
        value: channel?.channel_content,
        disabled: true,
      },
    ],
  },
  {
    container: {
      item: true,
      // xs: 12,
      // md: 4,
      // container: true,
    },
    items: [
      {
        name: "channel_logo",
        type: "custom",
        label: "بارگذاری لوگو کانال",
        render: () =>
          channel?.channel_logo ? (
            <Avatar
              src={channel?.channel_logo}
              sx={{ width: 116, height: "auto", mt: 3 }}
              variant="square"
            />
          ) : (
            <ChannelIcon style={{ width: 116 }} />
          ),
      },
    ],
  },
];

export const uploadFileForm = [
  {
    container: {
      item: true,
    },
    items: [
      {
        name: "file",
        type: "file",
        label: "بارگذاری فایل",
        accept: ".pdf",
      },
    ],
  },
];
