import React from "react";
import {
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  Toolbar,
} from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../../components/Layouts/Header";
import SideBar from "../../components/Layouts/SideBar";
import MyChannels from "./routes/MyChannels";
import Profile from "./routes/Profile";
import Settings from "./routes/Settings";
import Income from "./routes/Income";
import Contract from "./routes/Contract";
import Announcements from "./routes/Announcements";
import Tickets from "./routes/Tickets";
import MobileVerify from "./routes/MobileVerify";
import EmailVerify from "./routes/EmailVerify";
import Ticket from "./routes/Ticket";
import { useSelector } from "react-redux";
import ChannelDetails from "./routes/ChannelDetails";
import { backgroundColor } from "../../assets/colors";
import {
  GET_CHANNEL_DETAILS_STARTED,
  GET_SETTINGS_REPORT_STARTED,
  GET_TRANSACTIONS_STARTED,
  GET_USER_CHANNELS_STARTED,
  GET_USER_PROFILE_STARTED,
} from "../../redux/types";
import Cooperation from "./routes/Cooperation";

const App = () => {
  const { emailStatus, mobileStatus } = useSelector(
    (state) => state.authReducer
  );
  const { rType: youtubeRType } = useSelector((state) => state.youtubeReducer);
  const { rType: profileRType } = useSelector((state) => state.profileReducer);
  const { rType: settingsRType } = useSelector(
    (state) => state.settingsReducer
  );
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const loading =
    youtubeRType === GET_USER_CHANNELS_STARTED ||
    youtubeRType === GET_CHANNEL_DETAILS_STARTED ||
    profileRType === GET_USER_PROFILE_STARTED ||
    settingsRType === GET_SETTINGS_REPORT_STARTED;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderRoutes = () => {
    if (emailStatus !== "Active") {
      return (
        <>
          <Route path="/email-verify" element={<EmailVerify />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="tickets/ticket/:id" element={<Ticket />} />
          <Route path="*" element={<Navigate to="/email-verify" />} />
        </>
      );
    } else if (mobileStatus !== "Active") {
      return (
        <>
          <Route path="/mobile-verify" element={<MobileVerify />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="tickets/ticket/:id" element={<Ticket />} />
          <Route path="*" element={<Navigate to="/mobile-verify" />} />
        </>
      );
    } else {
      return (
        <>
          <Route path="/my-channels" element={<MyChannels />} />
          <Route
            path="/my-channels/channel-details/:id"
            element={<ChannelDetails />}
          />
          <Route path="/income" element={<Income />} />
          <Route path="/contract" element={<Contract />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/Announcements" element={<Announcements />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="tickets/ticket/:id" element={<Ticket />} />
          <Route path="cooperation" element={<Cooperation />} />
          <Route path="*" element={<Navigate to="/my-channels" />} />
        </>
      );
    }
  };

  const drawerWidth = 318;
  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" } }}>
      <CssBaseline />
      <Header
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <SideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          position: "relative",
        }}
      >
        <Toolbar />
        <Container sx={{ py: 5 }}>
          {loading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: backgroundColor,
              }}
            >
              <CircularProgress size={50} />
            </Box>
          )}
          <Routes>{renderRoutes()}</Routes>
        </Container>
      </Box>
    </Box>
  );
};

export default App;
