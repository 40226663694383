import {
  CREATE_REPLY_TICKET_FAILURE,
  CREATE_REPLY_TICKET_REQUEST,
  CREATE_REPLY_TICKET_STARTED,
  CREATE_REPLY_TICKET_SUCCESS,
  CREATE_TICKET_FAILURE,
  CREATE_TICKET_REQUEST,
  CREATE_TICKET_STARTED,
  CREATE_TICKET_SUCCESS,
  GET_ALL_TICKETS_FAILURE,
  GET_ALL_TICKETS_REQUEST,
  GET_ALL_TICKETS_STARTED,
  GET_ALL_TICKETS_SUCCESS,
  GET_TICKET_BADGE_FAILURE,
  GET_TICKET_BADGE_REQUEST,
  GET_TICKET_BADGE_STARTED,
  GET_TICKET_BADGE_SUCCESS,
  GET_TICKET_DETAILS_FAILURE,
  GET_TICKET_DETAILS_REQUEST,
  GET_TICKET_DETAILS_STARTED,
  GET_TICKET_DETAILS_SUCCESS,
} from "../types";
// --------------------------------------------------------------------------------
export const createTicketRequest = (data) => ({
  type: CREATE_TICKET_REQUEST,
  payload: data,
});

export const createTicketStarted = () => ({
  type: CREATE_TICKET_STARTED,
});

export const createTicketSuccess = (data) => ({
  type: CREATE_TICKET_SUCCESS,
  payload: data,
});

export const createTicketFailure = (data) => ({
  type: CREATE_TICKET_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAllTicketsRequest = (data) => ({
  type: GET_ALL_TICKETS_REQUEST,
  payload: data,
});

export const getAllTicketsStarted = () => ({
  type: GET_ALL_TICKETS_STARTED,
});

export const getAllTicketsSuccess = (data) => ({
  type: GET_ALL_TICKETS_SUCCESS,
  payload: data,
});

export const getAllTicketsFailure = (data) => ({
  type: GET_ALL_TICKETS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getTicketDetailsRequest = (data) => ({
  type: GET_TICKET_DETAILS_REQUEST,
  payload: data,
});

export const getTicketDetailsStarted = () => ({
  type: GET_TICKET_DETAILS_STARTED,
});

export const getTicketDetailsSuccess = (data) => ({
  type: GET_TICKET_DETAILS_SUCCESS,
  payload: data,
});

export const getTicketDetailsFailure = (data) => ({
  type: GET_TICKET_DETAILS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const createReplyTicketRequest = (data) => ({
  type: CREATE_REPLY_TICKET_REQUEST,
  payload: data,
});

export const createReplyTicketStarted = () => ({
  type: CREATE_REPLY_TICKET_STARTED,
});

export const createReplyTicketSuccess = (data) => ({
  type: CREATE_REPLY_TICKET_SUCCESS,
  payload: data,
});

export const createReplyTicketFailure = (data) => ({
  type: CREATE_REPLY_TICKET_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getTicketBadgeRequest = (data) => ({
  type: GET_TICKET_BADGE_REQUEST,
  payload: data,
});

export const getTicketBadgeStarted = () => ({
  type: GET_TICKET_BADGE_STARTED,
});

export const getTicketBadgeSuccess = (data) => ({
  type: GET_TICKET_BADGE_SUCCESS,
  payload: data,
});

export const getTicketBadgeFailure = (data) => ({
  type: GET_TICKET_BADGE_FAILURE,
  payload: data,
});
