import { useEffect, useState } from "react";
import {
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import TextInput, { BootstrapInput } from "../TextInput";
import { useDispatch } from "react-redux";
import { getUserChannelsAPI } from "../../services/API/Youtube";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// --------------------------------------------------------------------------------
const DropDown = ({ name, label, onChange, otherProps }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    setLoading(true);
    let result = await getUserChannelsAPI();
    if (result) {
      setItems(result.data);
      setLoading(false);
    }
  };

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel id="demo-customized-select-label" shrink disabled>
        {label}
      </InputLabel>
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        defaultValue={0}
        //   value={age}
        onChange={onChange}
        input={
          <BootstrapInput
            startAdornment={
              loading && (
                <InputAdornment position="end">
                  <CircularProgress size={15} />
                </InputAdornment>
              )
            }
          />
        }
        IconComponent={KeyboardArrowDownIcon}
        {...otherProps}
      >
        <MenuItem value="0">همه</MenuItem>
        {items.map((item) => (
          <MenuItem value={item?.id}>{item?.channel_name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDown;
