import axiosMiddleware from ".";
// --------------------------------------------------------------------------------
export const registerUserAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "register",
    body: data,
  });
export const loginUserAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "login",
    body: data,
  });
export const verifyUserAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "verify",
    body: data,
  });
export const sendEmailTokenAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user/email-token",
    body: data,
  });
export const verifyEmailAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user/email-verify",
    body: data,
  });
export const sendMobileOtpAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user/mobile-token",
    body: data,
  });
export const verifyMobileAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "user/mobile-verify",
    body: data,
  });
export const forgotEmailAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "forgot-email",
    body: data,
  });
export const forgotTokenAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "forgot-token",
    body: data,
  });
export const forgotPasswordAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "forgot-password",
    body: data,
  });
export const getBootAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "boot",
    body: data,
  });
