import MuiTableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styled from "@emotion/styled";

const TableRow = ({ columns, data }) => {
  return (
    <StyledTableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      hover
    >
      {columns?.map((column) => (
        <TableCell key={column.field} component="th" scope="row" align="center">
          {"renderCell" in column
            ? column.renderCell(data)
            : data[column.field]}
        </TableCell>
      ))}
    </StyledTableRow>
  );
};

export default TableRow;

const StyledTableRow = styled(MuiTableRow)(({ theme }) => ({
  backgroundColor: "#252E38",
  "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    backgroundColor: "#222933",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
