import { ErrorIcon, SuccessIcon } from "../../assets/SVGs";
import {
  CLOSE_MODAL_DEFAULT,
  CLOSE_MODAL_REQUEST,
  CLOSE_MODAL_SUCCESS,
  OPEN_ERROR_MODAL_SUCCESS,
  OPEN_MODAL_SUCCESS,
  OPEN_SUCCESS_MODAL_SUCCESS,
} from "../types";
// --------------------------------------------------------------------------------
const initialState = {
  rType: CLOSE_MODAL_DEFAULT,
  showDialog: false,
  title: "",
  description: "",
  status: "primary",
  content: <></>,
  onConfirm: () => {},
  btnText: "ثبت",
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL_SUCCESS:
      let { title, desc, status, content, onConfirm, btnText } = action.payload;
      return {
        ...state,
        rType: OPEN_MODAL_SUCCESS,
        showDialog: true,
        title,
        desc,
        status,
        content,
        onConfirm,
        btnText,
        error: "",
      };

    case OPEN_SUCCESS_MODAL_SUCCESS:
      return {
        ...state,
        rType: OPEN_SUCCESS_MODAL_SUCCESS,
        showDialog: true,
        desc: action.payload.desc,
        icon: (
          <SuccessIcon
            style={{ width: 120, marginBottom: 20, marginRight: 20 }}
          />
        ),
        error: "",
      };
    case OPEN_ERROR_MODAL_SUCCESS:
      return {
        ...state,
        rType: OPEN_ERROR_MODAL_SUCCESS,
        showDialog: true,
        desc: action.payload.desc,
        icon: <ErrorIcon style={{ width: 120, margin: 20 }} />,
        error: "",
      };
    case CLOSE_MODAL_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
