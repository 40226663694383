import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import Button from "../Button";
import TextInput from "../TextInput";
import { UploadFileIcon } from "../../assets/SVGs";
import { errorColor } from "../../assets/colors";
import ReCAPTCHA from "react-google-recaptcha";
import { REACT_APP_SITE_KEY } from "../../constants";
import { RE_DIGIT } from "../../constants";
// --------------------------------------------------------------------------------
const FormGenerator = ({
  formItems = [],
  initialValues = {},
  onSubmit,
  btnText = "تایید",
  loading,
}) => {
  const [formValues, setFormValues] = useState(formItems);
  const fileInputRef = useRef(null);

  const recaptchaRef = useRef();

  // useEffect(() => {
  //   let form = formItems?.map((obj) => ({
  //     ...obj,
  //     items: obj.items?.map((item) => ({
  //       ...item,
  //       value:
  //         initialValues && initialValues[item?.name]
  //           ? initialValues[item?.name]
  //           : "",
  //     })),
  //   }));
  //   setFormValues(form);
  // }, [formItems, initialValues]);

  useEffect(() => {
    let form = formItems?.map((obj) => ({
      ...obj,
      items: obj.items?.map((item) => ({
        ...item,
        value:
          item?.name in initialValues && initialValues[item?.name]
            ? initialValues[item?.name]
            : item?.value
            ? item?.value
            : "",
      })),
    }));
    setFormValues(form);
  }, [formItems]);

  const handleClearForsetFormValues = () => {
    let form = formItems?.map((obj) => ({
      ...obj,
      items: obj.items?.map((item) => ({
        ...item,
        value: "",
      })),
    }));
    setFormValues(form);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let values = handleValidation(formValues);

    let hasError = values.some((value) =>
      value.items.some((i) => "error" in i)
    );

    let v = {};
    values.forEach((value) =>
      value.items.forEach((i) => (v[i.name] = i.value))
    );
    if (!hasError) onSubmit(v, handleClearForsetFormValues);
  };

  const handleValidation = (formValues) => {
    let changedFormValues = formValues.map((obj) => ({
      ...obj,
      items: obj.items.map((item) => {
        if ((item.value === "" || item.value === false) && item.required)
          return { ...item, error: "پر کردن فیلد الزامی است" };
        if (
          item.name === "iban" &&
          (item.value.length < 24 || item.value.length > 24)
        )
          return { ...item, error: "شماره شبا صحیح نمی باشد" };
        else {
          delete item.error;
          return item;
        }
      }),
    }));
    setFormValues(changedFormValues);
    return changedFormValues;
  };

  const handleChange = (name, value) => {
    let changedFormValues = formValues.map((obj) => ({
      ...obj,
      items: obj.items.map((item) => {
        if (item.name === name) {
          switch (item.type) {
            case "iban":
              return { ...item, value: RE_DIGIT.test(value) ? value : "" };
            case "url":
              return {
                ...item,
                value: "https://" + value.substr("https://".length),
              };
            default:
              return { ...item, value };
          }
        } else {
          return { ...item };
        }
      }),
    }));
    setFormValues(changedFormValues);
  };

  const renderFileInput = (name, accept, value, error, disabled) => {
    return (
      <Box sx={{ mt: "24px" }}>
        {accept === ".pdf" && value && <Chip label={value?.name} />}
        <Box component="label">
          <input
            ref={fileInputRef}
            hidden
            accept={accept ? accept : "image/*"}
            type="file"
            onChange={(e) => handleChange(name, e.target.files[0])}
            disabled={disabled}
          />
          {accept === ".pdf" ? null : value ? (
            <Avatar
              src={
                typeof value === "string" ? value : URL.createObjectURL(value)
              }
              sx={{
                backgroundColor: "transparent",
                width: "200px",
                height: "200px",
                mt: 3,
              }}
              variant="rounded"
            />
          ) : (
            <UploadFileIcon color={error ? errorColor : "#0F1216"} />
          )}
        </Box>
      </Box>
    );
  };

  const renderItems = (items) => {
    return items?.map(
      ({
        type,
        name,
        label,
        text,
        multiline,
        rows,
        endAdornment,
        value,
        error,
        render,
        disabled,
        src,
        inputComponent,
        accept,
        prefix,
        english,
      }) => {
        switch (type) {
          case "typography":
            return (
              <Grid item xs={12}>
                <Typography variant="h5">{text}</Typography>
              </Grid>
            );
          case "text":
          case "password":
          case "iban":
          case "url":
            return (
              <Grid item xs={12}>
                <TextInput
                  name={name}
                  label={label}
                  type={type}
                  value={value}
                  onChange={(e) => handleChange(name, e.target.value)}
                  multiline={multiline}
                  row={rows}
                  endAdornment={endAdornment}
                  error={error}
                  helperText={error}
                  disabled={disabled}
                  inputComponent={inputComponent}
                  english={english}
                  // ref={(target) => {
                  //   target.value = "https://";
                  // }}
                />
              </Grid>
            );
          case "checkbox":
            return (
              <Grid item xs={12}>
                <FormControl error>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => handleChange(name, e.target.checked)}
                        />
                      }
                      label={label}
                    />
                  </FormGroup>
                  <FormHelperText>
                    {error ? "قبول کردن توافق نامه کاربری الزامی است" : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            );
          case "file":
            return (
              <Grid>
                {renderFileInput(name, accept, value, error, disabled)}
                <Button
                  text={label}
                  color="secondary"
                  onClick={() => fileInputRef.current.click()}
                  sx={{ mt: 2 }}
                  disabled={disabled}
                />
                <Button
                  text={btnText}
                  type="submit"
                  loading={loading}
                  sx={{ mt: 2 }}
                  disabled={disabled}
                />
              </Grid>
            );
          case "custom":
            return render();
          case "recaptcha":
            return (
              <Grid item xs={12}>
                <ReCAPTCHA
                  name={name}
                  // style={{ display: "inline-block" }}
                  theme="dark"
                  ref={recaptchaRef}
                  sitekey={REACT_APP_SITE_KEY}
                  value={value}
                  onChange={(value) => handleChange(name, value)}
                  onExpired={() => handleChange(name, "")}
                  er={error}
                />
                <FormHelperText error>{error}</FormHelperText>
              </Grid>
            );
          default:
            return (
              <Grid item xs={5} sx={{ m: "auto" }}>
                <Button
                  text={text ? text : "تایید"}
                  type="submit"
                  loading={loading}
                  sx={{ mt: 2 }}
                  disabled={disabled}
                />
              </Grid>
            );
        }
      }
    );
  };

  return (
    <Grid container spacing={3} sx={{ justifyContent: "center" }}>
      {formValues?.map(({ container, items }) => (
        <Grid
          item={container?.item}
          xs={container?.xs}
          sm={container?.sm}
          md={container?.md}
          container={container?.container}
          spacing={3}
          justifyContent="center"
          component="form"
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
        >
          {renderItems(items)}
        </Grid>
      ))}
    </Grid>
  );
};

export default FormGenerator;
