import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_SETTINGS_FAILURE,
  CHANGE_SETTINGS_STARTED,
  CHANGE_SETTINGS_SUCCESS,
  GET_SETTINGS_REPORT_FAILURE,
  GET_SETTINGS_REPORT_STARTED,
  GET_SETTINGS_REPORT_SUCCESS,
  GET_USER_PROFILE_DEFAULT,
} from "../types";
// --------------------------------------------------------------------------------
const initialState = {
  rType: GET_USER_PROFILE_DEFAULT,
  otp: false,
  smsNotification: false,
  emailNotification: false,
  settingsReport: [],
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SETTINGS_STARTED:
    case GET_SETTINGS_REPORT_STARTED:
    case CHANGE_PASSWORD_STARTED:
      return {
        ...state,
        rType: action.type,
      };
    case CHANGE_SETTINGS_SUCCESS:
    case GET_SETTINGS_REPORT_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
        error: "",
      };
    case CHANGE_SETTINGS_FAILURE:
    case GET_SETTINGS_REPORT_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
      };
    default:
      return state;
  }
};
