import { put, takeLatest } from "redux-saga/effects";
import {
  createYoutubeChannelAPI,
  getAffiliateTransactionsAPI,
  getAffiliateUsersAPI,
  getChannelDetailsAPI,
  getIntroducedFriendsAPI,
  getTransactionsAPI,
  getUserChannelsAPI,
  uploadContractFileAPI,
} from "../../services/API/Youtube";
import {
  createYoutubeChannelFailure,
  createYoutubeChannelStarted,
  createYoutubeChannelSuccess,
  getAffiliateTransactionsFailure,
  getAffiliateTransactionsStarted,
  getAffiliateTransactionsSuccess,
  getAffiliateUsersFailure,
  getAffiliateUsersStarted,
  getAffiliateUsersSuccess,
  getChannelDetailsFailure,
  getChannelDetailsStarted,
  getChannelDetailsSuccess,
  getIntroducedFriendsFailure,
  getIntroducedFriendsStarted,
  getIntroducedFriendsSuccess,
  getTransactionsFailure,
  getTransactionsStarted,
  getTransactionsSuccess,
  getUserChannelsFailure,
  getUserChannelsRequest,
  getUserChannelsStarted,
  getUserChannelsSuccess,
  uploadContractFileFailure,
  uploadContractFileStarted,
  uploadContractFileSuccess,
} from "../actions";
import {
  CREATE_YOUTUBE_CHANNEL_REQUEST,
  GET_AFFILIATE_TRANSACTIONS_REQUEST,
  GET_AFFILIATE_USERS_REQUEST,
  GET_CHANNEL_DETAILS_REQUEST,
  GET_INTRODUCED_FRIENDS_REQUEST,
  GET_INTRODUCED_FRIENDS_STARTED,
  GET_TRANSACTIONS_REQUEST,
  GET_USER_CHANNELS_REQUEST,
  UPLOAD_CONTRACT_FILE_REQUEST,
} from "../types";
// --------------------------------------------------------------------------------
function* onCreateYoutubeChannel({ payload }) {
  yield put(createYoutubeChannelStarted());
  try {
    let response = yield createYoutubeChannelAPI(payload);
    if (response.status === "success") {
      yield put(getUserChannelsRequest());
      yield put(createYoutubeChannelSuccess());
    } else {
      yield put(createYoutubeChannelFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(createYoutubeChannelFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetUserChannels({ payload }) {
  yield put(getUserChannelsStarted());
  try {
    let response = yield getUserChannelsAPI(payload);
    if (response.status === "success") {
      yield put(getUserChannelsSuccess({ userChannels: response.data }));
    } else {
      yield put(getUserChannelsFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getUserChannelsFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetTransactions({ payload }) {
  yield put(getTransactionsStarted());
  try {
    let response = yield getTransactionsAPI(payload);
    if (response.status === "success") {
      yield put(getTransactionsSuccess({ transactions: response.data }));
    } else {
      yield put(getTransactionsFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getTransactionsFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetChannelDetail({ payload }) {
  yield put(getChannelDetailsStarted());
  try {
    let response = yield getChannelDetailsAPI(payload);
    if (response.status === "success") {
      yield put(getChannelDetailsSuccess({ channelDetails: response.data }));
    } else {
      yield put(getChannelDetailsFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getChannelDetailsFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onUploadContacrtFile({ payload }) {
  yield put(uploadContractFileStarted());
  try {
    let response = yield uploadContractFileAPI(payload);
    if (response.status === "success") {
      yield put(uploadContractFileSuccess({ channelDetails: response.data }));
    } else {
      yield put(uploadContractFileFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(uploadContractFileFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetAffiliateTransactions({ payload }) {
  yield put(getAffiliateTransactionsStarted());
  try {
    let response = yield getAffiliateTransactionsAPI(payload);
    if (response.status === "success") {
      yield put(
        getAffiliateTransactionsSuccess({
          affiliateTransactions:
            "affiliates" in response.data ? response.data.affiliates : [],
        })
      );
    } else {
      yield put(
        getAffiliateTransactionsFailure({ error: response.errorMessage })
      );
    }
  } catch (error) {
    yield put(getAffiliateTransactionsFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetAffiliateUsers({ payload }) {
  yield put(getAffiliateUsersStarted());
  try {
    let response = yield getAffiliateUsersAPI(payload);
    if (response.status === "success") {
      yield put(getAffiliateUsersSuccess({ affiliateUsers: response.data }));
    } else {
      yield put(getAffiliateUsersFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getAffiliateUsersFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetIntroducedFriends({ payload }) {
  yield put(getIntroducedFriendsStarted());
  try {
    let response = yield getIntroducedFriendsAPI(payload);
    if (response.status === "success") {
      yield put(
        getIntroducedFriendsSuccess({ introducedFriends: response.data })
      );
    } else {
      yield put(getIntroducedFriendsFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getIntroducedFriendsFailure({ error: error.message }));
  }
}
// ================================================================================
function* youtubeSaga() {
  yield takeLatest(CREATE_YOUTUBE_CHANNEL_REQUEST, onCreateYoutubeChannel);
  yield takeLatest(GET_USER_CHANNELS_REQUEST, onGetUserChannels);
  yield takeLatest(GET_TRANSACTIONS_REQUEST, onGetTransactions);
  yield takeLatest(GET_CHANNEL_DETAILS_REQUEST, onGetChannelDetail);
  yield takeLatest(UPLOAD_CONTRACT_FILE_REQUEST, onUploadContacrtFile);
  yield takeLatest(
    GET_AFFILIATE_TRANSACTIONS_REQUEST,
    onGetAffiliateTransactions
  );
  yield takeLatest(GET_AFFILIATE_USERS_REQUEST, onGetAffiliateUsers);
  yield takeLatest(GET_INTRODUCED_FRIENDS_REQUEST, onGetIntroducedFriends);
}

export default youtubeSaga;
