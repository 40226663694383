import { Fragment } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Button from "../components/Button";
import { closeModalRequest } from "../redux/actions/Modal";
import App from "./app";
import Auth from "./auth";
// --------------------------------------------------------------------------------
const Pages = () => {
  const dispatch = useDispatch();
  const { rType, authToken } = useSelector((state) => state.authReducer);
  const { showDialog, content, btnText, onConfirm, title, desc, icon } =
    useSelector((state) => state.modalReducer);

  return (
    <Fragment>
      {authToken ? <App /> : <Auth />}
      <Dialog
        open={showDialog}
        onClose={() => dispatch(closeModalRequest())}
        PaperProps={{
          sx: { borderRadius: "10px", textAlign: "center" },
        }}
        maxWidth="xs"
        fullWidth
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent sx={{ p: 4 }}>
          {icon}
          <Box>
            <Typography>{desc}</Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3, px: 10 }}>
          <Button
            text="باشه"
            onClick={() => dispatch(closeModalRequest())}
            sx={{ mx: 5 }}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default Pages;
