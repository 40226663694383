import { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { borderColor } from "../../../../assets/colors";
import FormGenerator from "../../../../components/FormGenerator";
import { formItems, uploadFileForm } from "./formItems";
import Table from "../../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getChannelDetailsRequest } from "../../../../redux/actions";
import Button from "../../../../components/Button";
import { uploadContractFileRequest } from "./../../../../redux/actions/Youtube";
import {
  UPLOAD_CONTRACT_FILE_STARTED,
  UPLOAD_CONTRACT_FILE_SUCCESS,
} from "../../../../redux/types";
import { renderStatus } from "../../../../utils/helpers";
import { openModalRequest } from "../../../../redux/actions/Modal";
// --------------------------------------------------------------------------------
const ChannelDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { rType, channelDetails } = useSelector(
    (state) => state.youtubeReducer
  );
  const [showUplodModal, setShowUploadModal] = useState(false);
  const loading = rType === UPLOAD_CONTRACT_FILE_STARTED;

  useEffect(() => {
    dispatch(getChannelDetailsRequest({ id }));
  }, []);

  useEffect(() => {
    switch (rType) {
      case UPLOAD_CONTRACT_FILE_SUCCESS:
        dispatch(getChannelDetailsRequest({ id }));
        setShowUploadModal(false);
        break;
      default:
        break;
    }
  }, [rType]);

  const columns = [
    {
      field: "contract_date",
      headerName: "تاریخ شروع",
    },
    {
      field: "expire_date",
      headerName: "تاریخ پایان",
    },
    {
      field: "admin_contract_file",
      headerName: "فایل قرارداد شما",
      renderCell: ({ admin_contract_file }) => (
        <Button
          text="دانلود"
          href={admin_contract_file}
          target="_blank"
          disabled={admin_contract_file === null}
        />
      ),
    },
    {
      field: "user_contract_file",
      headerName: "فایل قرارداد امضا شده",
      renderCell: ({ user_contract_file, id, status }) => (
        <Button
          text="انتخاب فایل"
          color="secondary"
          onClick={() => setShowUploadModal(id)}
          disabled={status !== "Declined" && status !== "Uploading"}
        />
      ),
    },
    {
      field: "user_contract_file",
      headerName: "فایل قرارداد نهایی",
      renderCell: ({ final_contract_file }) => (
        <Button
          text="دانلود"
          href={final_contract_file}
          target="_blank"
          disabled={final_contract_file === null}
        />
      ),
    },
    {
      field: "status",
      headerName: "وضعیت",
      renderCell: ({ status }) => renderStatus({ status, variant: "text" }),
    },
    {
      field: "description",
      headerName: "توضیحات",
      renderCell: ({ description }) => (
        <Button
          variant="outlined"
          color="secondary"
          text="مشاهده"
          disabled={description === null}
          onClick={() =>
            dispatch(openModalRequest({ title: "توضیحات", desc: description }))
          }
        />
      ),
    },
  ];

  const renderUploadModal = (
    <Dialog
      open={showUplodModal}
      onClose={() => setShowUploadModal(false)}
      PaperProps={{ sx: { borderRadius: "10px", textAlign: "center" } }}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>آپلود قرارداد</DialogTitle>
      <DialogContent>
        <FormGenerator
          formItems={uploadFileForm}
          onSubmit={(values) =>
            dispatch(
              uploadContractFileRequest({
                ...values,
                contract_id: showUplodModal,
              })
            )
          }
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  );

  return (
    <>
      <Box sx={{ borderBottom: `1px solid ${borderColor}`, mb: 5 }}>
        <Typography gutterBottom>جزئیات کانال</Typography>
      </Box>
      <FormGenerator formItems={formItems(channelDetails?.channel)} />
      <Box sx={{ borderBottom: `1px solid ${borderColor}`, my: 5 }}>
        <Typography gutterBottom>لیست قراردادها</Typography>
      </Box>
      <Table columns={columns} data={channelDetails?.contract} />
      {renderUploadModal}
    </>
  );
};

export default ChannelDetails;
