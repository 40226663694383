export const specificationsForm = [
  {
    container: {
      container: true,
      item: true,
      xs: 12,
      md: 4,
    },
    items: [
      {
        name: "first_name",
        type: "text",
        label: "نام",
        disabled: true,
      },
      {
        name: "last_name",
        type: "text",
        label: "نام خانوادگی",
        disabled: true,
      },
      {
        name: "email",
        type: "text",
        label: "ایمیل",
        disabled: true,
      },
      {
        name: "mobile",
        type: "text",
        label: "شماره همراه",
        disabled: true,
      },
      {
        name: "national_code",
        type: "text",
        label: "کد ملی",
        required: true,
      },
    ],
  },
  {
    container: {
      item: true,
    },
    items: [
      {
        name: "identity_attachment",
        type: "file",
        label: "بارگذاری کارت ملی",
        required: true,
      },
    ],
  },
];

export const addressForm = [
  {
    container: {
      container: true,
      item: true,
      md: 4,
    },
    items: [
      {
        name: "state",
        type: "text",
        label: "استان",
        required: true,
      },
      {
        name: "city",
        type: "text",
        label: "شهر",
        required: true,
      },
      {
        name: "postal_code",
        type: "text",
        label: "کد پستی",
        required: true,
      },
    ],
  },
  {
    container: {
      container: true,
      item: true,
      md: 4,
    },
    items: [
      {
        name: "address",
        type: "text",
        label: "آدرس",
        multiline: true,
        rows: 5,
        required: true,
      },
      {},
    ],
  },
];

export const bankAccountForm = [
  {
    container: {
      container: true,
      item: true,
      md: 4,
    },
    items: [
      {
        name: "bank_branch",
        type: "text",
        label: "نام بانک",
        required: true,
      },
      {
        name: "card_number",
        type: "text",
        label: "شماره کارت",
        // required: true,
      },
      {
        name: "iban",
        type: "iban",
        label: "شماره شبا",
        required: true,
        endAdornment: "IR",
      },
      {},
    ],
  },
];
