import axiosMiddleware from ".";
// --------------------------------------------------------------------------------
export const createYoutubeChannelAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube-channel/create",
    body: data,
  });
export const getUserChannelsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube-channel/all",
    body: data,
  });
export const getTransactionsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube-channel/transactions",
    body: data,
  });
export const getChannelDetailsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube-channel/contract-list",
    body: data,
  });
export const uploadContractFileAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube-channel/contract/upload-file",
    body: data,
    config: {
      "Content-Type": "multipart/form-data",
    },
  });
export const getAffiliateTransactionsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube-channel/show",
    body: data,
  });
export const getAffiliateUsersAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube/affiliateUsers",
    body: data,
  });
export const getIntroducedFriendsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube/affiliateIncome",
    body: data,
  });
