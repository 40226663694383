export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const NON_ENGLISH_REGEX = /[^\x00-\x7F]+/gi;
export const NON_ALPHABETIC_REGEX = /[^A-Za-z\s]/g;
export const NON_DIGIT_REGEX = /\D/g;

export const UPPERCASE_LETTERS_REGEX = /[A-Z]+/;
export const LOWERCASE_LETTERS_REGEX = /[a-z]+/;
export const DIGIT_REGEX = /[0-9]+/;
export const SPECIAL_CHARACTERS_REGEX = /[^A-Za-z0-9]+/;
