export const formItems = [
  {
    container: {
      item: true,
      xs: 10,
      md: 5,
      container: true,
    },
    items: [
      {
        name: "subject",
        type: "text",
        label: "عنوان",
        required: true,
      },
      {
        name: "text",
        type: "text",
        label: "متن پیام",
        multiline: true,
        rows: 8,
        required: true,
      },
    ],
  },
  {
    container: {
      item: true,
    },
    items: [
      {
        name: "attachment",
        type: "file",
        label: "بارگذاری فایل",
      },
    ],
  },
];
