import { all } from "redux-saga/effects";
import authSaga from "./Auth";
import modalSaga from "./Modal";
import profileSaga from "./profile";
import settingsSaga from "./Settings";
import youtubeSaga from "./Youtube";
import ticketSaga from "./Ticket";
// --------------------------------------------------------------------------------
export default function* rootSaga() {
  yield all([
    authSaga(),
    youtubeSaga(),
    settingsSaga(),
    profileSaga(),
    modalSaga(),
    ticketSaga(),
  ]);
}
