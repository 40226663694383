import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { MobileVerifyIcon, OtpIcon } from "../../../../assets/SVGs";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Button";
import {
  sendMobileOtpRequest,
  verifyMobileRequest,
} from "../../../../redux/actions/Auth";
import {
  SEND_MOBILE_OTP_STARTED,
  SEND_MOBILE_OTP_SUCCESS,
  VERIFY_MOBILE_STARTED,
  VERIFY_MOBILE_SUCCESS,
} from "../../../../redux/types";
import { useNavigate } from "react-router";
import OtpInput from "../../../../components/OtpInput";
import FormGenerator from "../../../../components/FormGenerator";
import { formItems } from "./formItems";
// --------------------------------------------------------------------------------
const MobileVerify = () => {
  const dispatch = useDispatch();

  const { rType } = useSelector((state) => state.authReducer);
  const [mobile, setMobile] = useState("");
  const [counter, setCounter] = useState(120);
  const loading =
    rType === SEND_MOBILE_OTP_STARTED || rType === VERIFY_MOBILE_STARTED;

  const [showDialog, setShowDialog] = useState(false);
  const [otp, setOtp] = useState("");
  const onChange = (value) => setOtp(value);

  const renderCounter = () => {
    let seconds = (counter % 60).toString();
    let minutes = Math.floor(counter / 60).toString();
    minutes = minutes.length == 1 ? "0" + minutes : minutes;
    seconds = seconds.length == 1 ? "0" + seconds : seconds;
    return minutes + ":" + seconds;
  };

  useEffect(() => {
    let timer = null;
    if (showDialog) {
      timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [counter, showDialog]);

  useEffect(() => {
    switch (rType) {
      case SEND_MOBILE_OTP_SUCCESS:
        setShowDialog(true);
        setCounter(120);
        break;
      case VERIFY_MOBILE_SUCCESS:
        setShowDialog(false);
        break;
    }
  }, [rType]);

  const renderOtpModal = () => {
    return (
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        PaperProps={{
          sx: { borderRadius: 5 },
        }}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ p: 2 }}>
            <OtpIcon />
          </Box>
          <Typography variant="body2" sx={{ my: 2 }}>
            کد 5 رقمی ارسال شده به شماره همراه خود را وارد نمایید.
          </Typography>
          <OtpInput value={otp} valueLength={5} onChange={onChange} />
          <Typography sx={{ my: 2 }}>
            {counter > 0 ? (
              renderCounter()
            ) : (
              <Link
                href="#"
                color="grey.500"
                onClick={() =>
                  dispatch(
                    sendMobileOtpRequest({
                      mobile: `+98${mobile}`,
                      country_code: "+98",
                      mobile_without_code: mobile,
                    })
                  )
                }
              >
                درخواست مجدد کد
              </Link>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <Grid xs={5} sx={{ margin: "auto", p: 3 }}>
              <Button
                text="ثبت"
                onClick={() => {
                  dispatch(verifyMobileRequest({ token: otp }));
                }}
                loading={loading}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Grid container sx={{ justifyContent: "center" }}>
        <Box textAlign="center" sx={{ mb: 5 }}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>
            تایید تلفن همراه
          </Typography>
          <Typography>
            لطفا جهت دریافت کد تایید شماره همراه خود را وارد نمایید.
          </Typography>
        </Box>
        <FormGenerator
          formItems={formItems}
          onSubmit={({ mobile }) =>
            dispatch(
              sendMobileOtpRequest({
                mobile: `+98${mobile}`,
                country_code: "+98",
                mobile_without_code: mobile,
              })
            )
          }
          btnText="دریافت کد"
          loading={loading}
        />
        <Grid item xs={10}>
          <MobileVerifyIcon />
        </Grid>
      </Grid>
      {renderOtpModal()}
    </>
  );
};

export default MobileVerify;
