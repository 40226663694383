import { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import {
  forgotEmailForm,
  forgotPasswordForm,
  forgotTokenForm,
} from "./formItems";
import { useDispatch, useSelector } from "react-redux";
import { LoginIcon, YektuberIcon } from "../../../../assets/SVGs";
import {
  FORGOT_EMAIL_STARTED,
  FORGOT_EMAIL_SUCCESS,
  FORGOT_PASSWORD_STARTED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_TOKEN_STARTED,
  FORGOT_TOKEN_SUCCESS,
} from "../../../../redux/types";
import FormGenerator from "../../../../components/FormGenerator";
import { useNavigate } from "react-router-dom";
import { openSuccessModalRequest } from "../../../../redux/actions/Modal";
// --------------------------------------------------------------------------------
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { rType } = useSelector((state) => state.authReducer);
  const [formItems, setFormItems] = useState(forgotEmailForm);
  const loading =
    rType === FORGOT_EMAIL_STARTED ||
    rType === FORGOT_TOKEN_STARTED ||
    rType === FORGOT_PASSWORD_STARTED;

  useEffect(() => {
    switch (rType) {
      case FORGOT_EMAIL_SUCCESS:
        setFormItems(forgotTokenForm);
        dispatch(
          openSuccessModalRequest({
            desc: "کد تایید به آدرس پست الکترونیک شما ارسال گردید.",
          })
        );
        break;
      case FORGOT_TOKEN_SUCCESS:
        setFormItems(forgotPasswordForm);
        break;
      case FORGOT_PASSWORD_SUCCESS:
        navigate("/login");
        dispatch(
          openSuccessModalRequest({
            desc: "کلمه عبور شما با موفقیت تغییر یافت.",
          })
        );
        break;
      default:
        break;
    }
  }, [rType]);

  return (
    <Container sx={{ height: "100vh", display: "flex", alignItems: "center" }}>
      <Grid container sx={{ m: "auto" }}>
        <Grid
          container
          item
          sx={{ justifyContent: { xs: "center", md: "flex-end" }, my: 2 }}
        >
          <Grid item>
            <YektuberIcon width={110} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={5} sx={{ textAlign: "center" }}>
            <FormGenerator
              formItems={formItems}
              onSubmit={(values) => formItems[0].onSubmit(values)}
              loading={loading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 5,
              pl: { md: 5 },
            }}
          >
            <Grid item xs={8} md={12}>
              <LoginIcon />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgotPassword;
