import { Fragment, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Tooltip as MuiTooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { borderColor, sidebarColor } from "../../../../assets/colors";
import Card from "../../../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import {
  createYoutubeChannelRequest,
  getUserChannelsRequest,
} from "../../../../redux/actions";
import FormGenerator from "../../../../components/FormGenerator";
import { formItems } from "./formItems";
import {
  CREATE_YOUTUBE_CHANNEL_STARTED,
  CREATE_YOUTUBE_CHANNEL_SUCCESS,
} from "../../../../redux/types";
import styled from "@emotion/styled";
import { openSuccessModalRequest } from "../../../../redux/actions/Modal";
import { useNavigate } from "react-router-dom";
import { AddIcon, ChannelIcon } from "../../../../assets/SVGs";
import { renderStatus } from "../../../../utils/helpers";
// --------------------------------------------------------------------------------
const MyChannels = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { rType, userChannels } = useSelector((state) => state.youtubeReducer);
  const [showAddChannelModal, setShowAddChannelModal] = useState(false);
  const loading = rType === CREATE_YOUTUBE_CHANNEL_STARTED;

  useEffect(() => {
    dispatch(getUserChannelsRequest());
  }, []);

  useEffect(() => {
    switch (rType) {
      case CREATE_YOUTUBE_CHANNEL_SUCCESS:
        handleCloseAddChannelModal();
        dispatch(
          openSuccessModalRequest({
            desc: "درخواست ثبت کانال شما با موفقیت انجام شد.  کارشناسان پشتیبانی به زودی با شما تماس خواهند گرفت.",
          })
        );
        break;
      default:
        break;
    }
  }, [rType]);

  const handleOpenAddChannelModa = () => {
    setShowAddChannelModal(true);
  };

  const handleCloseAddChannelModal = () => {
    setShowAddChannelModal(false);
  };

  const renderAddChannelModal = (
    <Dialog
      open={showAddChannelModal}
      onClose={handleCloseAddChannelModal}
      PaperProps={{ sx: { borderRadius: "10px", textAlign: "center" } }}
      maxWidth="xs"
      fullWidth
      scroll="body"
    >
      <DialogTitle>افزودن کانال</DialogTitle>
      <DialogContent>
        <FormGenerator
          formItems={formItems}
          onSubmit={(values) => {
            let formData = new FormData();

            formData.append("channel_name", values.channel_name);
            formData.append("channel_link", values.channel_link);
            formData.append("channel_content", values.channel_content);
            formData.append("channel_logo", values.channel_logo);

            dispatch(createYoutubeChannelRequest(formData));
          }}
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  );

  return (
    <Fragment>
      <Box
        paddingY={5}
        marginBottom={3}
        borderBottom={`1px solid ${borderColor}`}
      >
        <Typography variant="h4" textAlign="center">
          کانال‌های من
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
      >
        {userChannels?.map(({ channel_name, channel_logo, status, id }) => {
          let imgSrc = channel_logo ? (
            <Avatar
              src={channel_logo}
              sx={{
                width: 103,
                height: 103,
                my: 1,
                mx: "auto",
              }}
            />
          ) : (
            <Box sx={{ my: 1, mx: "auto", width: 103, height: 103 }}>
              <ChannelIcon />
            </Box>
          );

          return (
            <Grid item>
              <Card
                onClick={() => navigate(`channel-details/${id}`)}
                sx={{ textAlign: "center" }}
              >
                {imgSrc}
                <Typography sx={{ mb: 1 }}>{channel_name}</Typography>
                <Divider variant="middle" />
                <Box sx={{ my: 1 }}>
                  {renderStatus({
                    status,
                    size: "small",
                  })}
                </Box>
              </Card>
            </Grid>
          );
        })}
        <Grid item>
          <Card onClick={handleOpenAddChannelModa} sx={{ textAlign: "center" }}>
            <Box sx={{ my: 2, mx: "auto", width: 103, height: 103 }}>
              <AddIcon />
            </Box>
            <Typography>افزودن کانال</Typography>
          </Card>
        </Grid>
      </Grid>
      {renderAddChannelModal}
    </Fragment>
  );
};

export default MyChannels;

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: sidebarColor,
    // color: successColor,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
