import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authReducer from "./Auth";
import settingsReducer from "./Settings";
import modalReducer from "./Modal";
import youtubeReducer from "./Youtube";
import ticketReducer from "./Ticket";
import profileReducer from "./Profile";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    youtubeReducer,
    settingsReducer,
    modalReducer,
    ticketReducer,
    profileReducer,
  });
