import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { formItems } from "./formItems";
import { useDispatch, useSelector } from "react-redux";
import { LoginIcon, OtpIcon, YektuberIcon } from "../../../../assets/SVGs";
import {
  LOGIN_USER_STARTED,
  LOGIN_USER_SUCCESS,
  VERIFY_USER_STARTED,
} from "../../../../redux/types";
import FormGenerator from "../../../../components/FormGenerator";
import { loginUserRequest, verifyUserRequest } from "../../../../redux/actions";
import OtpInput from "../../../../components/OtpInput";
import Button from "../../../../components/Button";
import { useParams } from "react-router-dom";
// --------------------------------------------------------------------------------
const Login = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { rType, otp: isOtp } = useSelector((state) => state.authReducer);
  const [counter, setCounter] = useState(120);
  const loading = rType === LOGIN_USER_STARTED;

  const [showDialog, setShowDialog] = useState();
  const [otp, setOtp] = useState("");
  const [formVlaues, setFormValues] = useState();
  const onChange = (value) => setOtp(value);

  const renderCounter = () => {
    let seconds = (counter % 60).toString();
    let minutes = Math.floor(counter / 60).toString();
    minutes = minutes.length == 1 ? "0" + minutes : minutes;
    seconds = seconds.length == 1 ? "0" + seconds : seconds;
    return minutes + ":" + seconds;
  };

  useEffect(() => {
    switch (rType) {
      case LOGIN_USER_SUCCESS:
        setCounter(120);
    }
  }, [rType]);

  useEffect(() => {
    let timer = null;
    if (showDialog) {
      timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [counter, showDialog]);

  useEffect(() => {
    if (isOtp) setShowDialog(true);
  }, [isOtp]);

  const renderOtpModal = (
    <Dialog
      open={showDialog}
      onClose={() => setShowDialog(false)}
      PaperProps={{
        sx: { borderRadius: 5 },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ p: 2 }}>
          <OtpIcon />
        </Box>
        <Typography variant="body2" sx={{ my: 2 }}>
          کد 5 رقمی ارسال شده به شماره همراه خود را وارد نمایید.
        </Typography>
        <OtpInput value={otp} valueLength={5} onChange={onChange} />
        <Typography sx={{ my: 2 }}>
          {counter > 0 ? (
            renderCounter()
          ) : (
            <Link
              href="#"
              color="grey.500"
              onClick={() => dispatch(loginUserRequest(formVlaues))}
            >
              درخواست مجدد کد
            </Link>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <Grid xs={5} sx={{ margin: "auto", p: 3 }}>
            <Button
              text="ثبت"
              onClick={() => {
                dispatch(
                  verifyUserRequest({ ...formVlaues, verify_token: otp })
                );
              }}
              loading={rType === VERIFY_USER_STARTED}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container sx={{ height: "100vh", display: "flex", alignItems: "center" }}>
      <Grid container sx={{ m: "auto" }}>
        <Grid
          container
          item
          sx={{ justifyContent: { xs: "center", md: "flex-end" }, my: 2 }}
        >
          <Grid item>
            <YektuberIcon width={110} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={5} sx={{ textAlign: "center" }}>
            <FormGenerator
              formItems={formItems({ email: params.email })}
              onSubmit={(values) => {
                dispatch(loginUserRequest({ ...values, source: "yektuber" }));
                setFormValues(values);
              }}
              loading={loading}
            />
            {/* <Grid item sx={{ mx: 10 }}>
              <Button text="ثبت نام با گوگل" color="secondary" />
            </Grid> */}
            {/* {"children" in formItems &&
              formItems?.children({ onClick: (form) => setFormItems(form) })} */}
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 5,
              pl: { md: 5 },
            }}
          >
            <Grid item xs={8} md={12}>
              <LoginIcon />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {renderOtpModal}
    </Container>
  );
};

export default Login;
