import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  affiliatesColumns,
  columns,
  toolbarItems,
  toolbarItems2,
} from "./formItems";
import { useDispatch, useSelector } from "react-redux";
import {
  getAffiliateTransactionsRequest,
  getTransactionsRequest,
} from "../../../../redux/actions";
import Table from "../../../../components/Table";
import dayjs from "dayjs";
// --------------------------------------------------------------------------------
const Income = () => {
  const dispatch = useDispatch();
  const { transactions, affiliateTransactions } = useSelector(
    (state) => state.youtubeReducer
  );

  useEffect(() => {
    let from = dayjs().subtract(3, "month").format("YYYY-MM-DD 00:00:00");
    let to = dayjs().format("YYYY-MM-DD 23:59:59");
    dispatch(getTransactionsRequest({ from, to }));
    dispatch(getAffiliateTransactionsRequest({ from, to }));
  }, []);

  return (
    <>
      <Grid container sx={{ mb: 5 }}>
        <Grid item sx={{ m: "20px auto" }}>
          <Typography variant="h4" textAlign="center">
            درآمد‌ها
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 10 }}>
        <Table
          columns={columns}
          data={transactions.transactions}
          toolbarItems={toolbarItems({ balance: transactions.balance })}
          title="تاریخچه درآمد"
        />
      </Grid>
      {affiliateTransactions.length > 0 && (
        <Grid container>
          <Table
            columns={affiliatesColumns}
            data={affiliateTransactions}
            toolbarItems={toolbarItems2}
            title="تراکنش های درآمد از یوتیوب"
          />
        </Grid>
      )}
    </>
  );
};

export default Income;
