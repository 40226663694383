import MuiCard from "@mui/material/Card";
import { paperColor } from "../../assets/colors";
import { CardActionArea } from "@mui/material";

const Card = ({ sx, children, onClick }) => {
  return (
    <MuiCard
      sx={{
        width: 201.81,
        height: 201.81,
        backgroundColor: paperColor,
        borderRadius: 3,
        display: "flex",
        ...sx,
      }}
    >
      <CardActionArea onClick={onClick}>{children}</CardActionArea>
    </MuiCard>
  );
};

export default Card;
