import { Box, Grid, Typography } from "@mui/material";
import { borderColor } from "../../../../assets/colors";
import { Fragment, useEffect, useState } from "react";
import Switch from "../../../../components/Switch";
import Table from "../../../../components/Table";
import FormGenerator from "../../../../components/FormGenerator";
import { columns, passwordChangeForm, tabs } from "./formItems";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordRequest,
  changeSettingsRequest,
  getSettingsReportRequest,
} from "../../../../redux/actions/Settings";
import {
  CHANGE_PASSWORD_STARTED,
  CHANGE_SETTINGS_STARTED,
} from "../../../../redux/types";
import Button from "../../../../components/Button";
import TabBar from "../../../../components/TabBar";
// --------------------------------------------------------------------------------
const Settings = () => {
  const dispatch = useDispatch();

  const {
    rType,
    otp: twoStepVerification,
    emailNotification,
    smsNotification,
    settingsReport,
  } = useSelector((state) => state.settingsReducer);
  const [activeTab, setActiveTab] = useState(1);
  const [otp, setOtp] = useState(twoStepVerification);
  const [smsNoti, setSmsNoti] = useState(smsNotification);
  const [emailNoti, setEmailNoti] = useState(emailNotification);
  const loading =
    rType === CHANGE_SETTINGS_STARTED || rType === CHANGE_PASSWORD_STARTED;

  useEffect(() => {
    dispatch(getSettingsReportRequest());
  }, []);

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const renderTabs = (activeTab) => {
    switch (activeTab) {
      case 1:
        return (
          <>
            <Grid container sx={{ mb: 10 }}>
              <Grid container justifyContent="center">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  md={5}
                  sx={{ borderBottom: `1px solid ${borderColor}`, py: 2 }}
                >
                  <Typography>ورود دو مرحله‌ای</Typography>
                  <Switch
                    checked={otp}
                    onChange={(e) => setOtp(e.target.checked)}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  md={5}
                  sx={{ borderBottom: `1px solid ${borderColor}`, py: 2 }}
                >
                  <Typography>اطلاع رسانی از طریق پیامک</Typography>
                  <Switch
                    checked={smsNoti}
                    onChange={(e) => setSmsNoti(e.target.checked)}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  md={5}
                  sx={{ borderBottom: `1px solid ${borderColor}`, py: 2 }}
                >
                  <Typography>اطلاع رسانی از طریق ایمیل</Typography>
                  <Switch
                    checked={emailNoti}
                    onChange={(e) => setEmailNoti(e.target.checked)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item xs={2}>
                <Button
                  text="ذخیره"
                  onClick={() =>
                    dispatch(
                      changeSettingsRequest({
                        otp: otp ? 1 : 0,
                        email_noti: emailNoti ? 1 : 0,
                        sms_noti: smsNoti ? 1 : 0,
                        renew: 0,
                      })
                    )
                  }
                  fullWidth
                  loading={loading}
                />
              </Grid>
            </Grid>
          </>
        );
      case 2:
        return (
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12}>
              <Table columns={columns} data={settingsReport} />
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <FormGenerator
            formItems={passwordChangeForm}
            onSubmit={(values) => dispatch(changePasswordRequest(values))}
            loading={loading}
          />
        );
    }
  };

  return (
    <Fragment>
      <Box paddingY={5} marginBottom={3}>
        <Typography variant="h4" textAlign="center">
          تنظیمات
        </Typography>
      </Box>
      <Grid container justifyContent="center" sx={{ mb: 10 }}>
        <Grid item xs={12} md={8}>
          <TabBar tabs={tabs} value={activeTab} onChange={handleChangeTab} />
        </Grid>
      </Grid>
      {renderTabs(activeTab)}
    </Fragment>
  );
};

export default Settings;
