import { put, takeLatest } from "redux-saga/effects";
import {
  closeModalSuccess,
  openErrorModalSuccess,
  openModalSuccess,
  openSuccessModalSuccess,
} from "../actions/Modal";
import {
  CLOSE_MODAL_REQUEST,
  OPEN_ERROR_MODAL_REQUEST,
  OPEN_MODAL_REQUEST,
  OPEN_SUCCESS_MODAL_REQUEST,
} from "../types";
// --------------------------------------------------------------------------------
function* onOpenModal({ payload }) {
  yield put(openModalSuccess(payload));
}
// --------------------------------------------------------------------------------
function* onCloseModal() {
  yield put(closeModalSuccess());
}
// --------------------------------------------------------------------------------
function* onOpenSuccessModal({ payload }) {
  yield put(openSuccessModalSuccess(payload));
}
// --------------------------------------------------------------------------------
function* onOpenErrorModal({ payload }) {
  yield put(openErrorModalSuccess(payload));
}
// ================================================================================
function* modalSaga() {
  yield takeLatest(OPEN_MODAL_REQUEST, onOpenModal);
  yield takeLatest(CLOSE_MODAL_REQUEST, onCloseModal);
  yield takeLatest(OPEN_SUCCESS_MODAL_REQUEST, onOpenSuccessModal);
  yield takeLatest(OPEN_ERROR_MODAL_REQUEST, onOpenErrorModal);
}

export default modalSaga;
