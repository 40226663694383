import EmailIcon from "@mui/icons-material/Email";
import { Grid, Link } from "@mui/material";
import { sendEmailTokenRequest } from "../../../../redux/actions/Auth";
import { store } from "../../../../redux/store";
// --------------------------------------------------------------------------------
export const formItems = [
  {
    container: {
      container: true,
      item: true,
      xs: 12,
      md: 4,
    },
    items: [
      {
        name: "token",
        type: "text",
        endAdornment: <EmailIcon />,
        required: true,
      },
      {},
      {
        type: "custom",
        render: () => (
          <Grid item>
            <Link
              href="#"
              color="grey.500"
              onClick={() => store.dispatch(sendEmailTokenRequest())}
            >
              در صورت عدم دریافت ایمیل اینجا کلیک نمایید.
            </Link>
          </Grid>
        ),
      },
    ],
  },
];
