import {
  CLOSE_MODAL_REQUEST,
  CLOSE_MODAL_SUCCESS,
  OPEN_ERROR_MODAL_REQUEST,
  OPEN_ERROR_MODAL_SUCCESS,
  OPEN_MODAL_REQUEST,
  OPEN_MODAL_SUCCESS,
  OPEN_SUCCESS_MODAL_REQUEST,
  OPEN_SUCCESS_MODAL_SUCCESS,
} from "../types";
// --------------------------------------------------------------------------------
export const openModalRequest = ({
  title,
  desc,
  status,
  onConfirm,
  content,
  btnText,
}) => {
  return {
    type: OPEN_MODAL_REQUEST,
    payload: {
      title,
      desc,
      status,
      onConfirm,
      content,
      btnText,
    },
  };
};

export const openModalSuccess = (data) => ({
  type: OPEN_MODAL_SUCCESS,
  payload: data,
});
// --------------------------------------------------------------------------------
export const closeModalRequest = () => {
  return {
    type: CLOSE_MODAL_REQUEST,
  };
};

export const closeModalSuccess = () => {
  return {
    type: CLOSE_MODAL_SUCCESS,
  };
};
// --------------------------------------------------------------------------------
export const openSuccessModalRequest = ({ desc }) => {
  return {
    type: OPEN_SUCCESS_MODAL_REQUEST,
    payload: {
      desc,
    },
  };
};

export const openSuccessModalSuccess = (data) => {
  return {
    type: OPEN_SUCCESS_MODAL_SUCCESS,
    payload: data,
  };
};
// --------------------------------------------------------------------------------
export const openErrorModalRequest = ({ desc }) => {
  return {
    type: OPEN_ERROR_MODAL_REQUEST,
    payload: {
      desc,
    },
  };
};

export const openErrorModalSuccess = (data) => {
  return {
    type: OPEN_ERROR_MODAL_SUCCESS,
    payload: data,
  };
};
