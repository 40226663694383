import {
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Toolbar,
  Typography,
} from "@mui/material";
import { borderColor } from "../../assets/colors";
import FormGenerator from "../FormGenerator";
import DropDown from "../DropDown";
import DatePicker from "../DatePicker";
import Button from "../Button";
import TextInput from "../TextInput";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionsRequest } from "../../redux/actions";
import { GET_TRANSACTIONS_STARTED } from "../../redux/types";
import { CsvBuilder } from "filefy";
import dayjs from "dayjs";
// --------------------------------------------------------------------------------
const TableToolbar = ({ toolbarItems, columns, data, title }) => {
  const dispatch = useDispatch();
  const { rType } = useSelector((state) => state.youtubeReducer);
  const [value, setValue] = useState("");
  const [formValues, setFormValues] = useState({
    from: dayjs().subtract(3, "month"),
    to: dayjs(),
  });
  const loading = rType === GET_TRANSACTIONS_STARTED;

  const exportTableCSV = () => {
    let exportColumns = columns.map((c) => c.headerName);
    let exportRows = [];

    data.forEach((d) => {
      columns.forEach((c) => exportRows.push(d[c.field]));
    });

    let result = [];
    exportRows.forEach((x, y, z) =>
      !(y % columns.length) ? result.push(z.slice(y, y + columns.length)) : ""
    );

    let csvBuilder = new CsvBuilder(`${title}.csv`)
      .setColumns(exportColumns)
      .addRows(result)
      .exportFile();
  };

  const renderToolbarItems = (toolbarItems) => {
    return toolbarItems?.map((item) => {
      switch (item.type) {
        case "text":
          return (
            <Grid item xs={2} sx={item.sx}>
              <TextInput
                label={item.label}
                value={item.value}
                readOnly={item.readOnly}
              />
            </Grid>
          );
        case "select":
          return (
            <Grid item xs={2}>
              <DropDown
                label={item.label}
                onChange={(e) =>
                  setFormValues({ ...formValues, [item.name]: e.target.value })
                }
              />
            </Grid>
          );
        case "datepicker":
          return (
            <Grid item xs={2}>
              <DatePicker
                label={item.label}
                value={formValues[item.name]}
                onChange={(e) =>
                  setFormValues({ ...formValues, [item.name]: e })
                }
              />
            </Grid>
          );
        case "submit":
          return (
            <Grid item xs={2}>
              <Button
                text={item.text}
                sx={{ mt: 3, p: 1 }}
                onClick={() => {
                  if (formValues.channel_id == 0) delete formValues.channel_id;
                  item.onClick({
                    ...formValues,
                    from: dayjs(formValues.from).format("YYYY-MM-DD 00:00:00"),
                    to: dayjs(formValues.to).format("YYYY-MM-DD 23:59:59"),
                  });
                }}
                loading={loading}
              />
            </Grid>
          );
        default:
          break;
      }
    });
  };

  return (
    <>
      <Toolbar disableGutters sx={{ mb: 5 }}>
        <Grid container spacing={2}>
          {renderToolbarItems(toolbarItems)}
        </Grid>
      </Toolbar>
      <Toolbar
        sx={{
          // pl: { sm: 2 },
          // pr: { xs: 1, sm: 1 },
          borderBottom: `1px solid ${borderColor}`,
          mb: 3,
          minHeight: { xs: 40 },
        }}
        disableGutters
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          // variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
        <IconButton onClick={() => exportTableCSV()}>
          <FileDownloadOutlinedIcon />
        </IconButton>
        <Input
          id="standard-adornment-password"
          placeholder="جستجو"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <IconButton disabled>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => setValue("")}
                disabled={value.length === 0}
              >
                <CloseIcon fontSize="5px" sx={{ cursor: "pointer" }} />
              </IconButton>
            </InputAdornment>
          }
          sx={{ pb: 1 }}
        />
      </Toolbar>
    </>
  );
};

export default TableToolbar;
