import axiosMiddleware from ".";
// --------------------------------------------------------------------------------
export const changeSettingsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "settings/general",
    body: data,
  });
export const getSettingsReportAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "settings/logs",
    body: data,
  });
export const changePasswordAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "settings/change-password",
    body: data,
  });
export const getAnnouncementsAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "/announcement/allAnnouncement",
    body: data,
  });
