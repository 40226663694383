import {
  CREATE_REPLY_TICKET_FAILURE,
  CREATE_REPLY_TICKET_STARTED,
  CREATE_REPLY_TICKET_SUCCESS,
  CREATE_TICKET_FAILURE,
  CREATE_TICKET_STARTED,
  CREATE_TICKET_SUCCESS,
  GET_ALL_TICKETS_FAILURE,
  GET_ALL_TICKETS_STARTED,
  GET_ALL_TICKETS_SUCCESS,
  GET_TICKET_BADGE_FAILURE,
  GET_TICKET_BADGE_STARTED,
  GET_TICKET_BADGE_SUCCESS,
  GET_TICKET_DETAILS_FAILURE,
  GET_TICKET_DETAILS_STARTED,
  GET_TICKET_DETAILS_SUCCESS,
  GET_USER_CHANNELS_DEFAULT,
} from "../types";
// --------------------------------------------------------------------------------
const initialState = {
  rType: GET_USER_CHANNELS_DEFAULT,
  tickets: [],
  selectedTicket: [],
  ticketBadge: 0,
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TICKET_STARTED:
    case GET_ALL_TICKETS_STARTED:
    case GET_TICKET_DETAILS_STARTED:
    case CREATE_REPLY_TICKET_STARTED:
    case GET_TICKET_BADGE_STARTED:
      return {
        ...state,
        rType: action.type,
      };
    case CREATE_TICKET_SUCCESS:
    case GET_ALL_TICKETS_SUCCESS:
    case GET_TICKET_DETAILS_SUCCESS:
    case CREATE_REPLY_TICKET_SUCCESS:
    case GET_TICKET_BADGE_SUCCESS:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
        error: "",
      };
    case CREATE_TICKET_FAILURE:
    case GET_ALL_TICKETS_FAILURE:
    case GET_TICKET_DETAILS_FAILURE:
    case CREATE_REPLY_TICKET_FAILURE:
    case GET_TICKET_BADGE_FAILURE:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
      };
    default:
      return state;
  }
};
