import { Chip, Typography } from "@mui/material";
import PersianDate from "jalaali-js";

export const getDateByLocale = (dateMiladi, lang = "fa") => {
  if (lang === "fa") {
    const dateTimeArr = dateMiladi.split(" ");
    const dateArr = dateTimeArr[0].split("-");
    const date = PersianDate.toJalaali(
      parseInt(dateArr[0]),
      parseInt(dateArr[1]),
      parseInt(dateArr[2])
    );
    return `${date.jy}/${date.jm}/${date.jd} ${dateTimeArr[1]}`;
  } else return dateMiladi.replace(/-/g, "/");
};

export const renderStatus = ({ status, variant, size }) => {
  let statusText = "";
  let statusColor = "";
  switch (status) {
    case "Pending":
      statusText = "درحال بررسی";
      statusColor = "warning";
      break;
    case "Approved":
      statusText = "تایید شده";
      statusColor = "success";
      break;
    case "Declined":
      statusText = "رد شده";
      statusColor = "error";
      break;
    case "Canceled":
      statusText = "لغو شده";
      statusColor = "error";
      break;
    case "Suspend":
      statusText = "تعلیق شده";
      statusColor = "error";
      break;
    case "Uploading":
      statusText = "بارگزاری قرارداد کاربر";
      statusColor = "secondary";
      break;
    default:
      break;
  }

  switch (variant) {
    case "text":
      return (
        statusColor &&
        statusText && (
          <Typography color={`${statusColor}.main`} variant="body2">
            {statusText}
          </Typography>
        )
      );
    default:
      return (
        statusColor &&
        statusText && (
          <Chip
            color={statusColor}
            label={statusText}
            variant="text"
            size={size}
            sx={{ fontSize: 10 }}
          />
        )
      );
  }
};
