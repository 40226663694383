import { useEffect, useState } from "react";
import {
  TableContainer,
  Table as MuiTable,
  Paper,
  TableHead,
  TableBody,
  TableRow as MuiTableRow,
  TablePagination,
} from "@mui/material";
import TableCell from "./Cell";
import TableRow from "./Row";
import TableToolbar from "./Toolbar";

const Table = ({ columns, data, toolbarItems, title }) => {
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (searchText.length) {
      let obj = [];
      data.filter((item) => {
        for (const i in item) {
          if (item[i] && typeof item[i] === "string") {
            if (
              item[i].toLowerCase().includes(searchText.toLowerCase()) &&
              !obj.includes(item)
            ) {
              obj.push(item);
            }
          }
        }
        setFilteredData(obj);
      });
    } else {
      setFilteredData(data);
    }
  }, [searchText, data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        {toolbarItems?.length > 0 && (
          <TableToolbar
            toolbarItems={toolbarItems}
            columns={columns}
            data={data}
            title={title}
          />
        )}
        <MuiTable sx={{ minWidth: 650 }}>
          <TableHead>
            <MuiTableRow>
              {columns?.map(({ field, headerName }) => (
                <TableCell key={field} align="center">
                  {headerName}
                </TableCell>
              ))}
            </MuiTableRow>
          </TableHead>
          <TableBody component={Paper}>
            {(data
              ? rowsPerPage > 0
                ? filteredData?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredData
              : filteredData
            )?.map((r) => {
              return <TableRow columns={columns} data={r} />;
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={data ? data.length : count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="ردیف در هر صفحه"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} از ${count !== -1 ? count : `more than ${to}`}`
        }
      />
    </>
  );
};

export default Table;
