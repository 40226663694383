import { Fragment } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { history } from "../../../../redux/store";
// --------------------------------------------------------------------------------
export const formItems = (params) => [
  {
    container: {
      item: true,
      xs: 12,
      sm: 10,
      container: true,
    },
    items: [
      {
        type: "typography",
        text: "ورود",
      },
      {
        name: "email",
        type: "text",
        value: params?.email,
        label: "ایمیل",
        required: true,
      },
      {
        name: "password",
        type: "password",
        label: "کلمه عبور",
        required: true,
      },
      {
        name: "recaptcha",
        type: "recaptcha",
        required: true,
      },
      {
        type: "submit",
        text: "ورود",
      },
      {
        type: "custom",
        render: () => (
          <Fragment>
            <Grid item xs={12}>
              <Typography>
                حساب کاربری ندارید؟{" "}
                <Link href="#" onClick={() => history.push("/register")}>
                  ساخت حساب کاربری
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <Link
                  href="#"
                  color="secondary"
                  onClick={() => history.push("/forgot-password")}
                >
                  رمز عبور خود را فراموش کرده‌اید؟
                </Link>
              </Typography>
            </Grid>
          </Fragment>
        ),
      },
    ],
  },
];
