// ---------------------------------------- AUTH ----------------------------------------
export const GET_BOOT_REQUEST = "GET_BOOT_REQUEST";
export const GET_BOOT_STARTED = "GET_BOOT_STARTED";
export const GET_BOOT_SUCCESS = "GET_BOOT_SUCCESS";
export const GET_BOOT_FAILURE = "GET_BOOT_FAILURE";

export const REGISTER_USER_DEFAULT = "REGISTER_USER_DEFAULT";
export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_STARTED = "REGISTER_USER_STARTED";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";

export const LOGIN_USER_DEFAULT = "LOGIN_USER_DEFAULT";
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_STARTED = "LOGIN_USER_STARTED";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

export const VERIFY_USER_DEFAULT = "VERIFY_USER_DEFAULT";
export const VERIFY_USER_REQUEST = "VERIFY_USER_REQUEST";
export const VERIFY_USER_STARTED = "VERIFY_USER_STARTED";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAILURE = "VERIFY_USER_FAILURE";

export const SEND_EMAIL_TOKEN_REQUEST = "SEND_EMAIL_TOKEN_REQUEST";
export const SEND_EMAIL_TOKEN_STARTED = "SEND_EMAIL_TOKEN_STARTED";
export const SEND_EMAIL_TOKEN_SUCCESS = "SEND_EMAIL_TOKEN_SUCCESS";
export const SEND_EMAIL_TOKEN_FAILURE = "SEND_EMAIL_TOKEN_FAILURE";

export const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_STARTED = "VERIFY_EMAIL_STARTED";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";

export const SEND_MOBILE_OTP_REQUEST = "SEND_MOBILE_OTP_REQUEST";
export const SEND_MOBILE_OTP_STARTED = "SEND_MOBILE_OTP_STARTED";
export const SEND_MOBILE_OTP_SUCCESS = "SEND_MOBILE_OTP_SUCCESS";
export const SEND_MOBILE_OTP_FAILURE = "SEND_MOBILE_OTP_FAILURE";

export const VERIFY_MOBILE_REQUEST = "VERIFY_MOBILE_REQUEST";
export const VERIFY_MOBILE_STARTED = "VERIFY_MOBILE_STARTED";
export const VERIFY_MOBILE_SUCCESS = "VERIFY_MOBILE_SUCCESS";
export const VERIFY_MOBILE_FAILURE = "VERIFY_MOBILE_FAILURE";

export const FORGOT_EMAIL_REQUEST = "FORGOT_EMAIL_REQUEST";
export const FORGOT_EMAIL_STARTED = "FORGOT_EMAIL_STARTED";
export const FORGOT_EMAIL_SUCCESS = "FORGOT_EMAIL_SUCCESS";
export const FORGOT_EMAIL_FAILURE = "FORGOT_EMAIL_FAILURE";

export const FORGOT_TOKEN_REQUEST = "FORGOT_TOKEN_REQUEST";
export const FORGOT_TOKEN_STARTED = "FORGOT_TOKEN_STARTED";
export const FORGOT_TOKEN_SUCCESS = "FORGOT_TOKEN_SUCCESS";
export const FORGOT_TOKEN_FAILURE = "FORGOT_TOKEN_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_STARTED = "FORGOT_PASSWORD_STARTED";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const VERIFY_ADMIN_REQUEST = "VERIFY_ADMIN_REQUEST";
export const VERIFY_ADMIN_STARTED = "VERIFY_ADMIN_STARTED";
export const VERIFY_ADMIN_SUCCESS = "VERIFY_ADMIN_SUCCESS";
export const VERIFY_ADMIN_FAILURE = "VERIFY_ADMIN_FAILURE";

export const LOGOUT_ADMIN_REQUEST = "LOGOUT_ADMIN_REQUEST";
export const LOGOUT_ADMIN_SUCCESS = "LOGOUT_ADMIN_SUCCESS";

// ---------------------------------------- YOUTUBE ----------------------------------------
export const CREATE_YOUTUBE_CHANNEL_REQUEST = "CREATE_YOUTUBE_CHANNEL_REQUEST";
export const CREATE_YOUTUBE_CHANNEL_STARTED = "CREATE_YOUTUBE_CHANNEL_STARTED";
export const CREATE_YOUTUBE_CHANNEL_SUCCESS = "CREATE_YOUTUBE_CHANNEL_SUCCESS";
export const CREATE_YOUTUBE_CHANNEL_FAILURE = "CREATE_YOUTUBE_CHANNEL_FAILURE";

export const GET_USER_CHANNELS_DEFAULT = "GET_USER_CHANNELS_DEFAULT";
export const GET_USER_CHANNELS_REQUEST = "GET_USER_CHANNELS_REQUEST";
export const GET_USER_CHANNELS_STARTED = "GET_USER_CHANNELS_STARTED";
export const GET_USER_CHANNELS_SUCCESS = "GET_USER_CHANNELS_SUCCESS";
export const GET_USER_CHANNELS_FAILURE = "GET_USER_CHANNELS_FAILURE";

export const GET_CHANNEL_DETAILS_REQUEST = "GET_CHANNEL_DETAILS_REQUEST";
export const GET_CHANNEL_DETAILS_STARTED = "GET_CHANNEL_DETAILS_STARTED";
export const GET_CHANNEL_DETAILS_SUCCESS = "GET_CHANNEL_DETAILS_SUCCESS";
export const GET_CHANNEL_DETAILS_FAILURE = "GET_CHANNEL_DETAILS_FAILURE";

export const UPLOAD_CONTRACT_FILE_REQUEST = "UPLOAD_CONTRACT_FILE_REQUEST";
export const UPLOAD_CONTRACT_FILE_STARTED = "UPLOAD_CONTRACT_FILE_STARTED";
export const UPLOAD_CONTRACT_FILE_SUCCESS = "UPLOAD_CONTRACT_FILE_SUCCESS";
export const UPLOAD_CONTRACT_FILE_FAILURE = "UPLOAD_CONTRACT_FILE_FAILURE";

export const GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST";
export const GET_TRANSACTIONS_STARTED = "GET_TRANSACTIONS_STARTED";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILURE = "GET_TRANSACTIONS_FAILURE";

export const GET_YOUTUBE_PAYOUTS_REQUEST = "GET_YOUTUBE_PAYOUTS_REQUEST";
export const GET_YOUTUBE_PAYOUTS_STARTED = "GET_YOUTUBE_PAYOUTS_STARTED";
export const GET_YOUTUBE_PAYOUTS_SUCCESS = "GET_YOUTUBE_PAYOUTS_SUCCESS";
export const GET_YOUTUBE_PAYOUTS_FAILURE = "GET_YOUTUBE_PAYOUTS_FAILURE";

export const GET_AFFILIATE_TRANSACTIONS_REQUEST =
  "GET_AFFILIATE_TRANSACTIONS_REQUEST";
export const GET_AFFILIATE_TRANSACTIONS_STARTED =
  "GET_AFFILIATE_TRANSACTIONS_STARTED";
export const GET_AFFILIATE_TRANSACTIONS_SUCCESS =
  "GET_AFFILIATE_TRANSACTIONS_SUCCESS";
export const GET_AFFILIATE_TRANSACTIONS_FAILURE =
  "GET_AFFILIATE_TRANSACTIONS_FAILURE";

export const GET_AFFILIATE_USERS_REQUEST = "GET_AFFILIATE_USERS_REQUEST";
export const GET_AFFILIATE_USERS_STARTED = "GET_AFFILIATE_USERS_STARTED";
export const GET_AFFILIATE_USERS_SUCCESS = "GET_AFFILIATE_USERS_SUCCESS";
export const GET_AFFILIATE_USERS_FAILURE = "GET_AFFILIATE_USERS_FAILURE";

export const GET_INTRODUCED_FRIENDS_REQUEST = "GET_INTRODUCED_FRIENDS_REQUEST";
export const GET_INTRODUCED_FRIENDS_STARTED = "GET_INTRODUCED_FRIENDS_STARTED";
export const GET_INTRODUCED_FRIENDS_SUCCESS = "GET_INTRODUCED_FRIENDS_SUCCESS";
export const GET_INTRODUCED_FRIENDS_FAILURE = "GET_INTRODUCED_FRIENDS_FAILURE";

// ---------------------------------------- SETTINGS ----------------------------------------
export const CHANGE_SETTINGS_REQUEST = "CHANGE_SETTINGS_REQUEST";
export const CHANGE_SETTINGS_STARTED = "CHANGE_SETTINGS_STARTED";
export const CHANGE_SETTINGS_SUCCESS = "CHANGE_SETTINGS_SUCCESS";
export const CHANGE_SETTINGS_FAILURE = "CHANGE_SETTINGS_FAILURE";

export const GET_SETTINGS_REPORT_REQUEST = "GET_SETTINGS_REPORT_REQUEST";
export const GET_SETTINGS_REPORT_STARTED = "GET_SETTINGS_REPORT_STARTED";
export const GET_SETTINGS_REPORT_SUCCESS = "GET_SETTINGS_REPORT_SUCCESS";
export const GET_SETTINGS_REPORT_FAILURE = "GET_SETTINGS_REPORT_FAILURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_STARTED = "CHANGE_PASSWORD_STARTED";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

// ---------------------------------------- PROFILE ----------------------------------------
export const GET_USER_PROFILE_DEFAULT = "GET_USER_PROFILE_DEFAULT";
export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_STARTED = "GET_USER_PROFILE_STARTED";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";

export const CHANGE_PROFILE_REQUEST = "CHANGE_PROFILE_REQUEST";
export const CHANGE_PROFILE_STARTED = "CHANGE_PROFILE_STARTED";
export const CHANGE_PROFILE_SUCCESS = "CHANGE_PROFILE_SUCCESS";
export const CHANGE_PROFILE_FAILURE = "CHANGE_PROFILE_FAILURE";

export const CHANGE_PROFILE_PICTURE_REQUEST = "CHANGE_PROFILE_PICTURE_REQUEST";
export const CHANGE_PROFILE_PICTURE_STARTED = "CHANGE_PROFILE_PICTURE_STARTED";
export const CHANGE_PROFILE_PICTURE_SUCCESS = "CHANGE_PROFILE_PICTURE_SUCCESS";
export const CHANGE_PROFILE_PICTURE_FAILURE = "CHANGE_PROFILE_PICTURE_FAILURE";

export const CHANGE_USER_ADDRESS_REQUEST = "CHANGE_USER_ADDRESS_REQUEST";
export const CHANGE_USER_ADDRESS_STARTED = "CHANGE_USER_ADDRESS_STARTED";
export const CHANGE_USER_ADDRESS_SUCCESS = "CHANGE_USER_ADDRESS_SUCCESS";
export const CHANGE_USER_ADDRESS_FAILURE = "CHANGE_USER_ADDRESS_FAILURE";

export const CHANGE_USER_BANK_ACCOUNT_REQUEST =
  "CHANGE_USER_BANK_ACCOUNT_REQUEST";
export const CHANGE_USER_BANK_ACCOUNT_STARTED =
  "CHANGE_USER_BANK_ACCOUNT_STARTED";
export const CHANGE_USER_BANK_ACCOUNT_SUCCESS =
  "CHANGE_USER_BANK_ACCOUNT_SUCCESS";
export const CHANGE_USER_BANK_ACCOUNT_FAILURE =
  "CHANGE_USER_BANK_ACCOUNT_FAILURE";

export const GET_ANNOUNCEMENTS_REQUEST = "GET_ANNOUNCEMENTS_REQUEST";
export const GET_ANNOUNCEMENTS_STARTED = "GET_ANNOUNCEMENTS_STARTED";
export const GET_ANNOUNCEMENTS_SUCCESS = "GET_ANNOUNCEMENTS_SUCCESS";
export const GET_ANNOUNCEMENTS_FAILURE = "GET_ANNOUNCEMENTS_FAILURE";

export const SEEN_ANNOUNCEMENT_REQUEST = "SEEN_ANNOUNCEMENT_REQUEST";
export const SEEN_ANNOUNCEMENT_STARTED = "SEEN_ANNOUNCEMENT_STARTED";
export const SEEN_ANNOUNCEMENT_SUCCESS = "SEEN_ANNOUNCEMENT_SUCCESS";
export const SEEN_ANNOUNCEMENT_FAILURE = "SEEN_ANNOUNCEMENT_FAILURE";

// ---------------------------------------- TICKETS ----------------------------------------
export const CREATE_TICKET_DEFAULT = "CREATE_TICKET_DEFAULT";
export const CREATE_TICKET_REQUEST = "CREATE_TICKET_REQUEST";
export const CREATE_TICKET_STARTED = "CREATE_TICKET_STARTED";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAILURE = "CREATE_TICKET_FAILURE";

export const GET_ALL_TICKETS_REQUEST = "GET_ALL_TICKETS_REQUEST";
export const GET_ALL_TICKETS_STARTED = "GET_ALL_TICKETS_STARTED";
export const GET_ALL_TICKETS_SUCCESS = "GET_ALL_TICKETS_SUCCESS";
export const GET_ALL_TICKETS_FAILURE = "GET_ALL_TICKETS_FAILURE";

export const GET_TICKET_DETAILS_REQUEST = "GET_TICKET_DETAILS_REQUEST";
export const GET_TICKET_DETAILS_STARTED = "GET_TICKET_DETAILS_STARTED";
export const GET_TICKET_DETAILS_SUCCESS = "GET_TICKET_DETAILS_SUCCESS";
export const GET_TICKET_DETAILS_FAILURE = "GET_TICKET_DETAILS_FAILURE";

export const CREATE_REPLY_TICKET_REQUEST = "CREATE_REPLY_TICKET_REQUEST";
export const CREATE_REPLY_TICKET_STARTED = "CREATE_REPLY_TICKET_STARTED";
export const CREATE_REPLY_TICKET_SUCCESS = "CREATE_REPLY_TICKET_SUCCESS";
export const CREATE_REPLY_TICKET_FAILURE = "CREATE_REPLY_TICKET_FAILURE";

export const GET_TICKET_BADGE_REQUEST = "GET_TICKET_BADGE_REQUEST";
export const GET_TICKET_BADGE_STARTED = "GET_TICKET_BADGE_STARTED";
export const GET_TICKET_BADGE_SUCCESS = "GET_TICKET_BADGE_SUCCESS";
export const GET_TICKET_BADGE_FAILURE = "GET_TICKET_BADGE_FAILURE";

// ---------------------------------------- MAIN ----------------------------------------
export const GET_DASHBOARD_INFO_DEFAULT = "GET_DASHBOARD_INFO_DEFAULT";
export const GET_DASHBOARD_INFO_REQUEST = "GET_DASHBOARD_INFO_REQUEST";
export const GET_DASHBOARD_INFO_STARTED = "GET_DASHBOARD_INFO_STARTED";
export const GET_DASHBOARD_INFO_SUCCESS = "GET_DASHBOARD_INFO_SUCCESS";
export const GET_DASHBOARD_INFO_FAILURE = "GET_DASHBOARD_INFO_FAILURE";

export const GET_PARSIAN_BALANCE_REQUEST = "GET_PARSIAN_BALANCE_REQUEST";
export const GET_PARSIAN_BALANCE_STARTED = "GET_PARSIAN_BALANCE_STARTED";
export const GET_PARSIAN_BALANCE_SUCCESS = "GET_PARSIAN_BALANCE_SUCCESS";
export const GET_PARSIAN_BALANCE_FAILURE = "GET_PARSIAN_BALANCE_FAILURE";

export const GET_AYANDE_BALANCE_REQUEST = "GET_AYANDE_BALANCE_REQUEST";
export const GET_AYANDE_BALANCE_STARTED = "GET_AYANDE_BALANCE_STARTED";
export const GET_AYANDE_BALANCE_SUCCESS = "GET_AYANDE_BALANCE_SUCCESS";
export const GET_AYANDE_BALANCE_FAILURE = "GET_AYANDE_BALANCE_FAILURE";

export const GET_KAVENEGAR_BALANCE_REQUEST = "GET_KAVENEGAR_BALANCE_REQUEST";
export const GET_KAVENEGAR_BALANCE_STARTED = "GET_KAVENEGAR_BALANCE_STARTED";
export const GET_KAVENEGAR_BALANCE_SUCCESS = "GET_KAVENEGAR_BALANCE_SUCCESS";
export const GET_KAVENEGAR_BALANCE_FAILURE = "GET_KAVENEGAR_BALANCE_FAILURE";

export const GET_ELASTIC_BALANCE_REQUEST = "GET_ELASTIC_BALANCE_REQUEST";
export const GET_ELASTIC_BALANCE_STARTED = "GET_ELASTIC_BALANCE_STARTED";
export const GET_ELASTIC_BALANCE_SUCCESS = "GET_ELASTIC_BALANCE_SUCCESS";
export const GET_ELASTIC_BALANCE_FAILURE = "GET_ELASTIC_BALANCE_FAILURE";

export const GET_ALL_PENDING_IDENTITY_REQUEST =
  "GET_ALL_PENDING_IDENTITY_REQUEST";
export const GET_ALL_PENDING_IDENTITY_STARTED =
  "GET_ALL_PENDING_IDENTITY_STARTED";
export const GET_ALL_PENDING_IDENTITY_SUCCESS =
  "GET_ALL_PENDING_IDENTITY_SUCCESS";
export const GET_ALL_PENDING_IDENTITY_FAILURE =
  "GET_ALL_PENDING_IDENTITY_FAILURE";

export const GET_ALL_PENDING_BANK_ACCOUNT_REQUEST =
  "GET_ALL_PENDING_BANK_ACCOUNT_REQUEST";
export const GET_ALL_PENDING_BANK_ACCOUNT_STARTED =
  "GET_ALL_PENDING_BANK_ACCOUNT_STARTED";
export const GET_ALL_PENDING_BANK_ACCOUNT_SUCCESS =
  "GET_ALL_PENDING_BANK_ACCOUNT_SUCCESS";
export const GET_ALL_PENDING_BANK_ACCOUNT_FAILURE =
  "GET_ALL_PENDING_BANK_ACCOUNT_FAILURE";

export const GET_ALL_PENDING_BILLING_ADDRESS_REQUEST =
  "GET_ALL_PENDING_BILLING_ADDRESS_REQUEST";
export const GET_ALL_PENDING_BILLING_ADDRESS_STARTED =
  "GET_ALL_PENDING_BILLING_ADDRESS_STARTED";
export const GET_ALL_PENDING_BILLING_ADDRESS_SUCCESS =
  "GET_ALL_PENDING_BILLING_ADDRESS_SUCCESS";
export const GET_ALL_PENDING_BILLING_ADDRESS_FAILURE =
  "GET_ALL_PENDING_BILLING_ADDRESS_FAILURE";

export const GET_FRAUD_SUSPECT_USERS_REQUEST =
  "GET_FRAUD_SUSPECT_USERS_REQUEST";
export const GET_FRAUD_SUSPECT_USERS_STARTED =
  "GET_FRAUD_SUSPECT_USERS_STARTED";
export const GET_FRAUD_SUSPECT_USERS_SUCCESS =
  "GET_FRAUD_SUSPECT_USERS_SUCCESS";
export const GET_FRAUD_SUSPECT_USERS_FAILURE =
  "GET_FRAUD_SUSPECT_USERS_FAILURE";

export const GET_BLOCKED_USERS_REQUEST = "GET_BLOCKED_USERS_REQUEST";
export const GET_BLOCKED_USERS_STARTED = "GET_BLOCKED_USERS_STARTED";
export const GET_BLOCKED_USERS_SUCCESS = "GET_BLOCKED_USERS_SUCCESS";
export const GET_BLOCKED_USERS_FAILURE = "GET_BLOCKED_USERS_FAILURE";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_STARTED = "GET_ALL_USERS_STARTED";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

export const GET_BUSINESS_USERS_REQUEST = "GET_BUSINESS_USERS_REQUEST";
export const GET_BUSINESS_USERS_STARTED = "GET_BUSINESS_USERS_STARTED";
export const GET_BUSINESS_USERS_SUCCESS = "GET_BUSINESS_USERS_SUCCESS";
export const GET_BUSINESS_USERS_FAILURE = "GET_BUSINESS_USERS_FAILURE";

export const GET_OPEN_TICKETS_REQUEST = "GET_OPEN_TICKETS_REQUEST";
export const GET_OPEN_TICKETS_STARTED = "GET_OPEN_TICKETS_STARTED";
export const GET_OPEN_TICKETS_SUCCESS = "GET_OPEN_TICKETS_SUCCESS";
export const GET_OPEN_TICKETS_FAILURE = "GET_OPEN_TICKETS_FAILURE";

export const CREATE_NEW_TICKET_REQUEST = "CREATE_NEW_TICKET_REQUEST";
export const CREATE_NEW_TICKET_STARTED = "CREATE_NEW_TICKET_STARTED";
export const CREATE_NEW_TICKET_SUCCESS = "CREATE_NEW_TICKET_SUCCESS";
export const CREATE_NEW_TICKET_FAILURE = "CREATE_NEW_TICKET_FAILURE";

export const CHANGE_TICKET_STATUS_REQUEST = "CHANGE_TICKET_STATUS_REQUEST";
export const CHANGE_TICKET_STATUS_STARTED = "CHANGE_TICKET_STATUS_STARTED";
export const CHANGE_TICKET_STATUS_SUCCESS = "CHANGE_TICKET_STATUS_SUCCESS";
export const CHANGE_TICKET_STATUS_FAILURE = "CHANGE_TICKET_STATUS_FAILURE";

export const CHANGE_TICKET_ROLE_REQUEST = "CHANGE_TICKET_ROLE_REQUEST";
export const CHANGE_TICKET_ROLE_STARTED = "CHANGE_TICKET_ROLE_STARTED";
export const CHANGE_TICKET_ROLE_SUCCESS = "CHANGE_TICKET_ROLE_SUCCESS";
export const CHANGE_TICKET_ROLE_FAILURE = "CHANGE_TICKET_ROLE_FAILURE";

export const DELETE_TICKET_REQUEST = "DELETE_TICKET_REQUEST";
export const DELETE_TICKET_STARTED = "DELETE_TICKET_STARTED";
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const DELETE_TICKET_FAILURE = "DELETE_TICKET_FAILURE";

export const ADD_TICKET_COMMENT_REQUEST = "ADD_TICKET_COMMENT_REQUEST";
export const ADD_TICKET_COMMENT_STARTED = "ADD_TICKET_COMMENT_STARTED";
export const ADD_TICKET_COMMENT_SUCCESS = "ADD_TICKET_COMMENT_SUCCESS";
export const ADD_TICKET_COMMENT_FAILURE = "ADD_TICKET_COMMENT_FAILURE";

// ---------------------------------------- MODAL ----------------------------------------
export const OPEN_MODAL_REQUEST = "OPEN_MODAL_REQUEST";
export const OPEN_MODAL_SUCCESS = "OPEN_MODAL_SUCCESS";

export const CLOSE_MODAL_DEFAULT = "CLOSE_MODAL_DEFAULT";
export const CLOSE_MODAL_REQUEST = "CLOSE_MODAL_REQUEST";
export const CLOSE_MODAL_SUCCESS = "CLOSE_MODAL_SUCCESS";

export const OPEN_SUCCESS_MODAL_REQUEST = "OPEN_SUCCESS_MODAL_REQUEST";
export const OPEN_SUCCESS_MODAL_SUCCESS = "OPEN_SUCCESS_MODAL_SUCCESS";

export const OPEN_ERROR_MODAL_REQUEST = "OPEN_ERROR_MODAL_REQUEST";
export const OPEN_ERROR_MODAL_SUCCESS = "OPEN_ERROR_MODAL_SUCCESS";
