import {
  CREATE_YOUTUBE_CHANNEL_FAILURE,
  CREATE_YOUTUBE_CHANNEL_REQUEST,
  CREATE_YOUTUBE_CHANNEL_STARTED,
  CREATE_YOUTUBE_CHANNEL_SUCCESS,
  GET_AFFILIATE_TRANSACTIONS_FAILURE,
  GET_AFFILIATE_TRANSACTIONS_REQUEST,
  GET_AFFILIATE_TRANSACTIONS_STARTED,
  GET_AFFILIATE_TRANSACTIONS_SUCCESS,
  GET_AFFILIATE_USERS_FAILURE,
  GET_AFFILIATE_USERS_REQUEST,
  GET_AFFILIATE_USERS_STARTED,
  GET_AFFILIATE_USERS_SUCCESS,
  GET_CHANNEL_DETAILS_FAILURE,
  GET_CHANNEL_DETAILS_REQUEST,
  GET_CHANNEL_DETAILS_STARTED,
  GET_CHANNEL_DETAILS_SUCCESS,
  GET_INTRODUCED_FRIENDS_FAILURE,
  GET_INTRODUCED_FRIENDS_REQUEST,
  GET_INTRODUCED_FRIENDS_STARTED,
  GET_INTRODUCED_FRIENDS_SUCCESS,
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_STARTED,
  GET_TRANSACTIONS_SUCCESS,
  GET_USER_CHANNELS_FAILURE,
  GET_USER_CHANNELS_REQUEST,
  GET_USER_CHANNELS_STARTED,
  GET_USER_CHANNELS_SUCCESS,
  UPLOAD_CONTRACT_FILE_FAILURE,
  UPLOAD_CONTRACT_FILE_REQUEST,
  UPLOAD_CONTRACT_FILE_STARTED,
  UPLOAD_CONTRACT_FILE_SUCCESS,
} from "../types";
// --------------------------------------------------------------------------------
export const createYoutubeChannelRequest = (data) => ({
  type: CREATE_YOUTUBE_CHANNEL_REQUEST,
  payload: data,
});

export const createYoutubeChannelStarted = () => ({
  type: CREATE_YOUTUBE_CHANNEL_STARTED,
});

export const createYoutubeChannelSuccess = (data) => ({
  type: CREATE_YOUTUBE_CHANNEL_SUCCESS,
  payload: data,
});

export const createYoutubeChannelFailure = (data) => ({
  type: CREATE_YOUTUBE_CHANNEL_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getUserChannelsRequest = (data) => ({
  type: GET_USER_CHANNELS_REQUEST,
  payload: data,
});

export const getUserChannelsStarted = () => ({
  type: GET_USER_CHANNELS_STARTED,
});

export const getUserChannelsSuccess = (data) => ({
  type: GET_USER_CHANNELS_SUCCESS,
  payload: data,
});

export const getUserChannelsFailure = (data) => ({
  type: GET_USER_CHANNELS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getTransactionsRequest = (data) => ({
  type: GET_TRANSACTIONS_REQUEST,
  payload: data,
});

export const getTransactionsStarted = () => ({
  type: GET_TRANSACTIONS_STARTED,
});

export const getTransactionsSuccess = (data) => ({
  type: GET_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const getTransactionsFailure = (data) => ({
  type: GET_TRANSACTIONS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getChannelDetailsRequest = (data) => ({
  type: GET_CHANNEL_DETAILS_REQUEST,
  payload: data,
});

export const getChannelDetailsStarted = () => ({
  type: GET_CHANNEL_DETAILS_STARTED,
});

export const getChannelDetailsSuccess = (data) => ({
  type: GET_CHANNEL_DETAILS_SUCCESS,
  payload: data,
});

export const getChannelDetailsFailure = (data) => ({
  type: GET_CHANNEL_DETAILS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const uploadContractFileRequest = (data) => ({
  type: UPLOAD_CONTRACT_FILE_REQUEST,
  payload: data,
});

export const uploadContractFileStarted = () => ({
  type: UPLOAD_CONTRACT_FILE_STARTED,
});

export const uploadContractFileSuccess = (data) => ({
  type: UPLOAD_CONTRACT_FILE_SUCCESS,
  payload: data,
});

export const uploadContractFileFailure = (data) => ({
  type: UPLOAD_CONTRACT_FILE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAffiliateTransactionsRequest = (data) => ({
  type: GET_AFFILIATE_TRANSACTIONS_REQUEST,
  payload: data,
});

export const getAffiliateTransactionsStarted = () => ({
  type: GET_AFFILIATE_TRANSACTIONS_STARTED,
});

export const getAffiliateTransactionsSuccess = (data) => ({
  type: GET_AFFILIATE_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const getAffiliateTransactionsFailure = (data) => ({
  type: GET_AFFILIATE_TRANSACTIONS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAffiliateUsersRequest = (data) => ({
  type: GET_AFFILIATE_USERS_REQUEST,
  payload: data,
});

export const getAffiliateUsersStarted = () => ({
  type: GET_AFFILIATE_USERS_STARTED,
});

export const getAffiliateUsersSuccess = (data) => ({
  type: GET_AFFILIATE_USERS_SUCCESS,
  payload: data,
});

export const getAffiliateUsersFailure = (data) => ({
  type: GET_AFFILIATE_USERS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getIntroducedFriendsRequest = (data) => ({
  type: GET_INTRODUCED_FRIENDS_REQUEST,
  payload: data,
});

export const getIntroducedFriendsStarted = () => ({
  type: GET_INTRODUCED_FRIENDS_STARTED,
});

export const getIntroducedFriendsSuccess = (data) => ({
  type: GET_INTRODUCED_FRIENDS_SUCCESS,
  payload: data,
});

export const getIntroducedFriendsFailure = (data) => ({
  type: GET_INTRODUCED_FRIENDS_FAILURE,
  payload: data,
});
