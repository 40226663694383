import { put, takeLatest } from "redux-saga/effects";
import {
  forgotEmailAPI,
  forgotPasswordAPI,
  forgotTokenAPI,
  getBootAPI,
  loginUserAPI,
  registerUserAPI,
  sendEmailTokenAPI,
  sendMobileOtpAPI,
  verifyEmailAPI,
  verifyMobileAPI,
  verifyUserAPI,
} from "../../services/API/Auth";
import {
  forgotEmailFailure,
  forgotEmailStarted,
  forgotEmailSuccess,
  forgotPasswordFailure,
  forgotPasswordStarted,
  forgotPasswordSuccess,
  forgotTokenFailure,
  forgotTokenStarted,
  forgotTokenSuccess,
  getBootFailure,
  getBootStarted,
  getBootSuccess,
  loginUserFailure,
  loginUserStarted,
  loginUserSuccess,
  logoutAdminSuccess,
  registerUserFailure,
  registerUserStarted,
  registerUserSuccess,
  sendEmailTokenFailure,
  sendEmailTokenStarted,
  sendEmailTokenSuccess,
  sendMobileOtpFailure,
  sendMobileOtpStarted,
  sendMobileOtpSuccess,
  verifyEmailFailure,
  verifyEmailStarted,
  verifyEmailSuccess,
  verifyMobileFailure,
  verifyMobileStarted,
  verifyMobileSuccess,
  verifyUserFailure,
  verifyUserStarted,
  verifyUserSuccess,
} from "../actions/Auth";
import {
  FORGOT_EMAIL_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_TOKEN_REQUEST,
  GET_BOOT_REQUEST,
  LOGIN_USER_REQUEST,
  LOGOUT_ADMIN_REQUEST,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  SEND_EMAIL_TOKEN_REQUEST,
  SEND_MOBILE_OTP_REQUEST,
  SEND_MOBILE_REQUEST,
  VERIFY_ADMIN_REQUEST,
  VERIFY_EMAIL_REQUEST,
  VERIFY_MOBILE_REQUEST,
  VERIFY_MOBILE_STARTED,
  VERIFY_USER_REQUEST,
} from "../types";
import { changeSettingsSuccess } from "../actions/Settings";
import { openSuccessModalRequest } from "../actions/Modal";
// --------------------------------------------------------------------------------
function* onRegisterUser({ payload }) {
  yield put(registerUserStarted());
  try {
    let response = yield registerUserAPI(payload);
    if (response.status === "success") {
      let {
        token,
        first_name,
        last_name,
        email,
        mobile,
        otp,
        sms_notification,
        email_notification,
      } = response.data;
      yield put(
        registerUserSuccess({
          authToken: token,
          // fullName: `${first_name} ${last_name}`,
          firstName: first_name,
          lastName: last_name,
          email,
          mobile,
          otp: otp === "Active" ? true : false,
          smsNotification: sms_notification === "Active" ? true : false,
          emailNotification: email_notification === "Active" ? true : false,
        })
      );
    } else {
      yield put(registerUserFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(registerUserFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onLoginUser({ payload }) {
  yield put(loginUserStarted());
  try {
    let response = yield loginUserAPI(payload);
    if (response.status === "success") {
      let {
        token,
        first_name,
        last_name,
        email,
        mobile,
        otp,
        sms_notification,
        email_notification,
        email_status,
        mobile_status,
        profile_picture,
        promotion_code,
      } = response.data;
      yield put(
        loginUserSuccess({
          authToken: token,
          firstName: first_name,
          lastName: last_name,
          email,
          mobile,
          otp,
          emailStatus: email_status,
          mobileStatus: mobile_status,
          profileSrc: profile_picture ? profile_picture : null,
          promotionCode: promotion_code,
        })
      );
      yield put(
        changeSettingsSuccess({
          otp: otp === "Active" ? true : false,
          smsNotification: sms_notification === "Active" ? true : false,
          emailNotification: email_notification === "Active" ? true : false,
        })
      );
    } else {
      yield put(loginUserFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(loginUserFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onVerifyUser({ payload }) {
  yield put(verifyUserStarted());
  try {
    let response = yield verifyUserAPI(payload);
    if (response.status === "success") {
      let {
        token,
        first_name,
        last_name,
        email,
        mobile,
        otp,
        sms_notification,
        email_notification,
        email_status,
        mobile_status,
        profile_picture,
      } = response.data;
      yield put(
        verifyUserSuccess({
          authToken: token,
          firstName: first_name,
          lastName: last_name,
          email,
          mobile,
          otp,
          emailStatus: email_status,
          mobileStatus: mobile_status,
          profileSrc: profile_picture ? profile_picture : null,
        })
      );
      yield put(
        changeSettingsSuccess({
          otp: otp === "Active" ? true : false,
          smsNotification: sms_notification === "Active" ? true : false,
          emailNotification: email_notification === "Active" ? true : false,
        })
      );
    } else {
      yield put(verifyUserFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(verifyUserFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onForgotEmail({ payload }) {
  yield put(forgotEmailStarted());
  try {
    let response = yield forgotEmailAPI(payload);
    if (response.status === "success") {
      yield put(forgotEmailSuccess());
    } else {
      yield put(forgotEmailFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(forgotEmailFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onForgotToken({ payload }) {
  yield put(forgotTokenStarted());
  try {
    let response = yield forgotTokenAPI(payload);
    if (response.status === "success") {
      yield put(forgotTokenSuccess());
    } else {
      yield put(forgotTokenFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(forgotTokenFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onForgotPassword({ payload }) {
  yield put(forgotPasswordStarted());
  try {
    let response = yield forgotPasswordAPI(payload);
    if (response.status === "success") {
      yield put(forgotPasswordSuccess());
    } else {
      yield put(forgotPasswordFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(forgotPasswordFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onSendEmailToken({ payload }) {
  yield put(sendEmailTokenStarted());
  try {
    let response = yield sendEmailTokenAPI(payload);
    if (response.status === "success") {
      yield put(sendEmailTokenSuccess());
      yield put(
        openSuccessModalRequest({
          desc: "کد فعالسازی به آدرس ایمیل شما ارسال گردید",
        })
      );
    } else {
      yield put(sendEmailTokenFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(sendEmailTokenFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onVerifyEmail({ payload }) {
  yield put(verifyEmailStarted());
  try {
    let response = yield verifyEmailAPI(payload);
    if (response.status === "success") {
      yield put(verifyEmailSuccess({ emailStatus: "Active" }));
    } else {
      yield put(verifyEmailFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(verifyEmailFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onSendMobileOtp({ payload }) {
  yield put(sendMobileOtpStarted());
  try {
    let response = yield sendMobileOtpAPI(payload);
    if (response.status === "success") {
      yield put(sendMobileOtpSuccess());
    } else {
      yield put(sendMobileOtpFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(sendMobileOtpFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onVerifyMobile({ payload }) {
  yield put(verifyMobileStarted());
  try {
    let response = yield verifyMobileAPI(payload);
    if (response.status === "success") {
      yield put(verifyMobileSuccess({ mobileStatus: "Active" }));
    } else {
      yield put(verifyMobileFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(verifyMobileFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onLogoutAdmin() {
  yield put(logoutAdminSuccess());
}
// --------------------------------------------------------------------------------
function* onGetBoot({ payload }) {
  yield put(getBootStarted());
  try {
    let response = yield getBootAPI(payload);
    if (response.status === "success") {
      yield put(getBootSuccess({ boot: response.data }));
    } else {
      yield put(getBootFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getBootFailure({ error: error.message }));
  }
}
// ================================================================================
function* authSaga() {
  yield takeLatest(GET_BOOT_REQUEST, onGetBoot);
  yield takeLatest(REGISTER_USER_REQUEST, onRegisterUser);
  yield takeLatest(LOGIN_USER_REQUEST, onLoginUser);
  yield takeLatest(VERIFY_USER_REQUEST, onVerifyUser);
  yield takeLatest(FORGOT_EMAIL_REQUEST, onForgotEmail);
  yield takeLatest(FORGOT_TOKEN_REQUEST, onForgotToken);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, onForgotPassword);
  yield takeLatest(VERIFY_ADMIN_REQUEST, onVerifyEmail);
  yield takeLatest(LOGOUT_ADMIN_REQUEST, onLogoutAdmin);
  yield takeLatest(SEND_EMAIL_TOKEN_REQUEST, onSendEmailToken);
  yield takeLatest(VERIFY_EMAIL_REQUEST, onVerifyEmail);
  yield takeLatest(SEND_MOBILE_OTP_REQUEST, onSendMobileOtp);
  yield takeLatest(VERIFY_MOBILE_REQUEST, onVerifyMobile);
}

export default authSaga;
