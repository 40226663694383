import {
  CREATE_YOUTUBE_CHANNEL_FAILURE,
  CREATE_YOUTUBE_CHANNEL_STARTED,
  CREATE_YOUTUBE_CHANNEL_SUCCESS,
  GET_AFFILIATE_TRANSACTIONS_FAILURE,
  GET_AFFILIATE_TRANSACTIONS_STARTED,
  GET_AFFILIATE_TRANSACTIONS_SUCCESS,
  GET_AFFILIATE_USERS_FAILURE,
  GET_AFFILIATE_USERS_STARTED,
  GET_AFFILIATE_USERS_SUCCESS,
  GET_CHANNEL_DETAILS_FAILURE,
  GET_CHANNEL_DETAILS_STARTED,
  GET_CHANNEL_DETAILS_SUCCESS,
  GET_INTRODUCED_FRIENDS_FAILURE,
  GET_INTRODUCED_FRIENDS_STARTED,
  GET_INTRODUCED_FRIENDS_SUCCESS,
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_STARTED,
  GET_TRANSACTIONS_SUCCESS,
  GET_USER_CHANNELS_DEFAULT,
  GET_USER_CHANNELS_FAILURE,
  GET_USER_CHANNELS_STARTED,
  GET_USER_CHANNELS_SUCCESS,
  UPLOAD_CONTRACT_FILE_FAILURE,
  UPLOAD_CONTRACT_FILE_STARTED,
  UPLOAD_CONTRACT_FILE_SUCCESS,
} from "../types";
// --------------------------------------------------------------------------------
const initialState = {
  rType: GET_USER_CHANNELS_DEFAULT,
  userChannels: [],
  transactions: [],
  affiliateTransactions: [],
  introducedFriends: [],
  affiliateUsers: [],
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_YOUTUBE_CHANNEL_STARTED:
    case GET_USER_CHANNELS_STARTED:
    case GET_AFFILIATE_TRANSACTIONS_STARTED:
    case GET_TRANSACTIONS_STARTED:
    case GET_CHANNEL_DETAILS_STARTED:
    case UPLOAD_CONTRACT_FILE_STARTED:
    case GET_AFFILIATE_USERS_STARTED:
    case GET_INTRODUCED_FRIENDS_STARTED:
      return {
        ...state,
        rType: action.type,
      };
    case CREATE_YOUTUBE_CHANNEL_SUCCESS:
    case GET_USER_CHANNELS_SUCCESS:
    case GET_AFFILIATE_TRANSACTIONS_SUCCESS:
    case GET_TRANSACTIONS_SUCCESS:
    case GET_CHANNEL_DETAILS_SUCCESS:
    case UPLOAD_CONTRACT_FILE_SUCCESS:
    case GET_AFFILIATE_USERS_SUCCESS:
    case GET_INTRODUCED_FRIENDS_SUCCESS:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
        error: "",
      };
    case CREATE_YOUTUBE_CHANNEL_FAILURE:
    case GET_USER_CHANNELS_FAILURE:
    case GET_AFFILIATE_TRANSACTIONS_FAILURE:
    case GET_TRANSACTIONS_FAILURE:
    case GET_CHANNEL_DETAILS_FAILURE:
    case UPLOAD_CONTRACT_FILE_FAILURE:
    case GET_AFFILIATE_USERS_FAILURE:
    case GET_INTRODUCED_FRIENDS_FAILURE:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
      };
    default:
      return state;
  }
};
