import { Fragment, useEffect, useState } from "react";
import { Box, Grid, Tooltip as MuiTooltip, Typography } from "@mui/material";
import { borderColor, textColor } from "../../../../assets/colors";
import Table from "../../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { getDateByLocale } from "../../../../utils/helpers";
import Button from "../../../../components/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  getAffiliateUsersRequest,
  getIntroducedFriendsRequest,
} from "../../../../redux/actions";
import dayjs from "dayjs";
import moment from "moment-jalaali";
// --------------------------------------------------------------------------------
const Cooperation = () => {
  const dispatch = useDispatch();

  const { promotionCode } = useSelector((state) => state.authReducer);
  const { introducedFriends, affiliateUsers } = useSelector(
    (state) => state.youtubeReducer
  );
  const [buttonText, setButtonText] = useState("کپی");
  const columns = [
    {
      field: "full_name",
      headerName: "نام",
    },
    {
      field: "channel_name",
      headerName: "نام کانال",
    },
    {
      field: "register_date",
      headerName: "تاریخ ثبت نام",
      renderCell: (row) => moment(row.register_date).format("jYYYY/jMM/jDD"),
    },
  ];
  const transactionsColumns = [
    {
      field: "from_date",
      headerName: "از تاریخ",
      renderCell: (row) => dayjs(row.from_date).format("YYYY-MM-DD"),
    },
    {
      field: "to_date",
      headerName: "تا تاریخ",
      renderCell: (row) => dayjs(row.to_date).format("YYYY-MM-DD"),
    },
    {
      field: "amount",
      headerName: "مبلغ درآمد (ریال)",
    },
  ];

  useEffect(() => {
    dispatch(getAffiliateUsersRequest());
    dispatch(getIntroducedFriendsRequest());
  }, []);

  const handleCopyReferralCode = () => {
    navigator.clipboard.writeText(promotionCode);
    setButtonText("کپی شد");

    setTimeout(() => {
      setButtonText("کپی");
    }, 2000);
  };

  return (
    <Fragment>
      <Box
        paddingY={5}
        marginBottom={3}
        borderBottom={`1px solid ${borderColor}`}
      >
        <Typography variant="h4" textAlign="center">
          همکاری در بازاریابی
        </Typography>
      </Box>
      <Grid xs={12}>
        <Typography sx={{ mb: 5, pr: 2, lineHeight: 2 }}>
          شما می‌توانید با دعوت از دوستان خود به یکتیوبر، درآمد خود را افزایش
          دهید. کافی است کد معرف را در اختیار دوستان خود بگذارید و از ایشان
          بخواهید در زمان ثبت نام از آن استفاده کنند.{" "}
        </Typography>
      </Grid>
      <Grid container spacing={1} sx={{ justifyContent: "center", pb: 4 }}>
        <Grid item xs={12} lg={2} sx={{ order: { xs: 1, lg: 0 } }}>
          <Button
            startIcon={<ContentCopyIcon />}
            sx={{ py: 1.1, boxShadow: "none" }}
            text={buttonText}
            onClick={handleCopyReferralCode}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <Box
            sx={{ p: 1, border: `1px dashed ${textColor}`, borderRadius: 1 }}
          >
            <Typography sx={{ textAlign: "center" }}>
              {promotionCode}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ justifyContent: "center" }}>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: `1px solid ${borderColor}` }}>
            <Typography gutterBottom>لیست دوستان معرفی شده</Typography>
          </Box>
          <Table columns={columns} data={affiliateUsers} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: `1px solid ${borderColor}` }}>
            <Typography gutterBottom>لیست درآمدها</Typography>
          </Box>
          <Table columns={transactionsColumns} data={introducedFriends} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Cooperation;
