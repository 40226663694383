import {
  ButtonGroup,
  Button as MuiButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// --------------------------------------------------------------------------------
const TabBar = ({ tabs, value, onChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ButtonGroup
      size="small"
      color="secondary"
      fullWidth
      orientation={isMobile ? "vertical" : "horizontal"}
    >
      {tabs?.map((tab) => (
        <MuiButton
          key={tab.value}
          variant={value === tab.value ? "contained" : "outlined"}
          onClick={() => onChange(tab.value)}
          sx={{ p: 2 }}
        >
          {tab.text}
        </MuiButton>
      ))}
    </ButtonGroup>
  );
};

export default TabBar;
