import { getDateByLocale } from "../../../../utils/helpers";

export const tabs = [
  { text: "عمومی", value: 1 },
  { text: "رویدادها", value: 2 },
  { text: "تغییر رمز عبور", value: 3 },
];

export const passwordChangeForm = [
  {
    container: {
      container: true,
      item: true,
      xs: 12,
      sm: 8,
      md: 5,
    },
    items: [
      {
        name: "current_password",
        type: "password",
        label: "کلمه عبور فعلی",
        required: true,
      },
      {
        name: "new_password",
        type: "password",
        label: "کلمه عبور جدید",
        required: true,
      },
      {
        name: "confirm_new_password",
        type: "password",
        label: "تکرار کلمه عبور جدید",
        required: true,
      },
      {},
    ],
  },
];

export const columns = [
  {
    field: "operation",
    headerName: "عملیات",
  },
  {
    field: "device",
    headerName: "دستگاه",
    renderCell: ({ device, platform_version }) =>
      device + " " + platform_version,
  },
  {
    field: "ip_address",
    headerName: "آی پی",
  },
  {
    field: "created_at",
    headerName: "تاریخ",
    renderCell: ({ created_at }) => getDateByLocale(created_at),
  },
];
