import {
  getAffiliateTransactionsRequest,
  getTransactionsRequest,
} from "../../../../redux/actions";
import { store } from "../../../../redux/store";

export const columns = [
  {
    field: "channel_name",
    headerName: "نام کانال",
  },
  {
    field: "mounth_income",
    headerName: "تاریخ درآمد",
  },
  {
    field: "total_amount",
    headerName: "مبلغ کل درآمد (لیر)",
  },
  {
    field: "yekpay_commission_amount",
    headerName: "کارمزد (لیر)",
  },
  {
    field: "yekpay_commission_percent",
    headerName: "درصد کارمزد",
  },
  {
    field: "rate",
    headerName: "نرخ تبدیل (ریال)",
  },
  {
    field: "user_irr_amount",
    headerName: "مبلغ درآمد شما (ریال)",
  },
  {
    field: "created_at",
    headerName: "تاریخ ثبت",
  },
  {
    field: "deposit_date",
    headerName: "تاریخ واریز",
  },
  {
    field: "reference_code",
    headerName: "شماره پیگیری بانکی",
  },
];

export const affiliatesColumns = [
  {
    field: "from_date",
    headerName: "از تاریخ",
  },
  {
    field: "to_date",
    headerName: "تا تاریخ",
  },
  {
    field: "youtube_channel_id",
    headerName: "نام کانال",
  },
  {
    field: "total_amount",
    headerName: "مبلغ کل واریزی (لیر)",
    // renderCell: (rowData) => numberWithCommas(rowData.total_amount),
  },
  {
    field: "affiliate_commission_percent",
    headerName: "درصد کمیسیون",
  },
  {
    field: "affiliate_commission_amount",
    headerName: "مبلغ شارژ شده (لیر)",
    // renderCell: (rowData) =>
    //   numberWithCommas(rowData.affiliate_commission_amount),
  },
  {
    field: "currency_rate_id",
    headerName: "نرخ ارز لیر",
    // renderCell: (rowData) => numberWithCommas(rowData.currency_rate_id),
  },

  {
    field: "affiliate_irr_amount",
    headerName: "مبلغ شارژ شده (ریال)",
    // renderCell: (rowData) => numberWithCommas(rowData.affiliate_irr_amount),
  },
  {
    field: "created_at",
    headerName: "تاریخ ثبت",
  },
];

export const formItems = [];

export const toolbarItems = ({ balance }) => [
  {
    type: "select",
    name: "channel_id",
    label: "نام کانال",
  },
  {
    type: "datepicker",
    name: "from",
    label: "از تاریخ",
  },
  {
    type: "datepicker",
    name: "to",
    label: "تا تاریخ",
  },
  {
    type: "submit",
    text: "جستجو",
    onClick: (data) => store.dispatch(getTransactionsRequest(data)),
  },
  {
    type: "text",
    label: "موجودی (ریال)",
    readOnly: true,
    value: balance,
    sx: { ml: "auto" },
  },
];

export const toolbarItems2 = [
  {
    type: "datepicker",
    name: "from",
    label: "از تاریخ",
  },
  {
    type: "datepicker",
    name: "to",
    label: "تا تاریخ",
  },
  {
    type: "submit",
    text: "جستجو",
    onClick: (data) => store.dispatch(getAffiliateTransactionsRequest(data)),
  },
];
