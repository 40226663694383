import { put, takeLatest } from "redux-saga/effects";
import {
  changePasswordAPI,
  changeSettingsAPI,
  getSettingsReportAPI,
} from "../../services/API/Settings";
import {
  changePasswordFailure,
  changePasswordStarted,
  changePasswordSuccess,
  changeSettingsFailure,
  changeSettingsStarted,
  changeSettingsSuccess,
  getSettingsReportFailure,
  getSettingsReportStarted,
  getSettingsReportSuccess,
} from "../actions/Settings";
import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_SETTINGS_REQUEST,
  GET_SETTINGS_REPORT_REQUEST,
} from "../types";
import { openSuccessModalRequest } from "../actions/Modal";
// --------------------------------------------------------------------------------
function* onChangeSettings({ payload }) {
  yield put(changeSettingsStarted());
  try {
    let response = yield changeSettingsAPI(payload);
    if (response.status === "success") {
      let { otp, sms_notification, email_notification } = response.data;
      yield put(
        changeSettingsSuccess({
          otp: otp === "Active" ? true : false,
          smsNotification: sms_notification === "Active" ? true : false,
          emailNotification: email_notification === "Active" ? true : false,
        })
      );
      yield put(
        openSuccessModalRequest({ desc: "تغییرات با موفیقیت ذخیره شد" })
      );
    } else {
      yield put(changeSettingsFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(changeSettingsFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGetSettingsReport() {
  yield put(getSettingsReportStarted());
  try {
    let response = yield getSettingsReportAPI();
    if (response.status === "success") {
      yield put(getSettingsReportSuccess({ settingsReport: response.data }));
    } else {
      yield put(getSettingsReportFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getSettingsReportFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onChangePassword({ payload }) {
  yield put(changePasswordStarted());
  try {
    let response = yield changePasswordAPI(payload);
    if (response.status === "success") {
      yield put(changePasswordSuccess());
      yield put(
        openSuccessModalRequest({
          desc: "کلمه عبور شما با موفقیت تغییر یافت.",
        })
      );
    } else {
      yield put(changePasswordFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(changePasswordFailure({ error: error.message }));
  }
}

// ================================================================================
function* settingsSaga() {
  yield takeLatest(CHANGE_SETTINGS_REQUEST, onChangeSettings);
  yield takeLatest(GET_SETTINGS_REPORT_REQUEST, onGetSettingsReport);
  yield takeLatest(CHANGE_PASSWORD_REQUEST, onChangePassword);
}

export default settingsSaga;
