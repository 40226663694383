import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
// --------------------------------------------------------------------------------
export const formItems = [
  {
    container: {
      container: true,
      item: true,
      xs: 12,
      md: 4,
    },
    items: [
      {
        name: "mobile",
        type: "text",
        endAdornment: <PhoneAndroidIcon />,
        required: true,
      },
      {},
    ],
  },
];
