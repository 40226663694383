import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PROFILE_FAILURE,
  CHANGE_PROFILE_PICTURE_FAILURE,
  CHANGE_PROFILE_PICTURE_STARTED,
  CHANGE_PROFILE_PICTURE_SUCCESS,
  CHANGE_PROFILE_STARTED,
  CHANGE_PROFILE_SUCCESS,
  CHANGE_SETTINGS_FAILURE,
  CHANGE_SETTINGS_STARTED,
  CHANGE_SETTINGS_SUCCESS,
  CHANGE_USER_ADDRESS_FAILURE,
  CHANGE_USER_ADDRESS_STARTED,
  CHANGE_USER_ADDRESS_SUCCESS,
  CHANGE_USER_BANK_ACCOUNT_FAILURE,
  CHANGE_USER_BANK_ACCOUNT_STARTED,
  CHANGE_USER_BANK_ACCOUNT_SUCCESS,
  FORGOT_EMAIL_FAILURE,
  FORGOT_EMAIL_STARTED,
  FORGOT_EMAIL_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_STARTED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_TOKEN_FAILURE,
  FORGOT_TOKEN_STARTED,
  FORGOT_TOKEN_SUCCESS,
  GET_ANNOUNCEMENTS_FAILURE,
  GET_ANNOUNCEMENTS_STARTED,
  GET_ANNOUNCEMENTS_SUCCESS,
  GET_SETTINGS_REPORT_FAILURE,
  GET_SETTINGS_REPORT_STARTED,
  GET_SETTINGS_REPORT_SUCCESS,
  GET_USER_PROFILE_DEFAULT,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_STARTED,
  GET_USER_PROFILE_SUCCESS,
  LOGIN_ADMIN_DEFAULT,
  LOGIN_USER_DEFAULT,
  LOGIN_USER_FAILURE,
  LOGIN_USER_STARTED,
  LOGIN_USER_SUCCESS,
  LOGOUT_ADMIN_SUCCESS,
  SEEN_ANNOUNCEMENT_FAILURE,
  SEEN_ANNOUNCEMENT_STARTED,
  SEEN_ANNOUNCEMENT_SUCCESS,
  VERIFY_ADMIN_FAILURE,
  VERIFY_ADMIN_STARTED,
  VERIFY_ADMIN_SUCCESS,
} from "../types";
// --------------------------------------------------------------------------------
const initialState = {
  rType: GET_USER_PROFILE_DEFAULT,
  userData: {},
  announcements: [],
  settingsReport: [],
  error: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_STARTED:
    case GET_ANNOUNCEMENTS_STARTED:
    case CHANGE_PROFILE_PICTURE_STARTED:
    case CHANGE_SETTINGS_STARTED:
    case GET_SETTINGS_REPORT_STARTED:
    case CHANGE_PASSWORD_STARTED:
    case CHANGE_PROFILE_STARTED:
    case CHANGE_USER_ADDRESS_STARTED:
    case CHANGE_USER_BANK_ACCOUNT_STARTED:
    case SEEN_ANNOUNCEMENT_STARTED:
      return {
        ...state,
        rType: action.type,
      };
    case GET_USER_PROFILE_SUCCESS:
    case GET_ANNOUNCEMENTS_SUCCESS:
    case CHANGE_PROFILE_PICTURE_SUCCESS:
    case CHANGE_SETTINGS_SUCCESS:
    case GET_SETTINGS_REPORT_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
    case CHANGE_PROFILE_SUCCESS:
    case CHANGE_USER_ADDRESS_SUCCESS:
    case CHANGE_USER_BANK_ACCOUNT_SUCCESS:
    case SEEN_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
        error: "",
      };
    case GET_USER_PROFILE_FAILURE:
    case GET_ANNOUNCEMENTS_FAILURE:
    case CHANGE_PROFILE_PICTURE_FAILURE:
    case CHANGE_SETTINGS_FAILURE:
    case GET_SETTINGS_REPORT_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
    case CHANGE_PROFILE_FAILURE:
    case CHANGE_USER_ADDRESS_FAILURE:
    case CHANGE_USER_BANK_ACCOUNT_FAILURE:
    case SEEN_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
      };
    default:
      return state;
  }
};
