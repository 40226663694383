import { put, takeLatest } from "redux-saga/effects";
import {
  changeProfileAPI,
  changeProfilePictureAPI,
  changeUserAddressAPI,
  changeUserBankAccountAPI,
  getUserProfileAPI,
  seenAnnouncementAPI,
} from "../../services/API/Profile";
import { getAnnouncementsAPI } from "../../services/API/Settings";
import {
  changeProfileFailure,
  changeProfilePictureFailure,
  changeProfilePictureStarted,
  changeProfilePictureSuccess,
  changeProfileStarted,
  changeProfileSuccess,
  changeUserAddressFailure,
  changeUserAddressStarted,
  changeUserAddressSuccess,
  changeUserBankAccountFailure,
  changeUserBankAccountStarted,
  changeUserBankAccountSuccess,
  getAnnouncementsFailure,
  getAnnouncementsRequest,
  getAnnouncementsStarted,
  getAnnouncementsSuccess,
  getUserProfileFailure,
  getUserProfileRequest,
  getUserProfileStarted,
  getUserProfileSuccess,
  seenAnnouncementFailure,
  seenAnnouncementStarted,
  seenAnnouncementSuccess,
} from "../actions/Profile";
import {
  CHANGE_PROFILE_PICTURE_REQUEST,
  CHANGE_PROFILE_REQUEST,
  CHANGE_USER_ADDRESS_REQUEST,
  CHANGE_USER_BANK_ACCOUNT_REQUEST,
  GET_ANNOUNCEMENTS_REQUEST,
  GET_USER_PROFILE_REQUEST,
  SEEN_ANNOUNCEMENT_REQUEST,
} from "../types";
import { openSuccessModalRequest } from "../actions/Modal";
// --------------------------------------------------------------------------------
function* onGetUserProfile({ payload }) {
  yield put(getUserProfileStarted());
  try {
    let response = yield getUserProfileAPI(payload);
    if (response.status === "success") {
      yield put(getUserProfileSuccess({ userData: response.data }));
    } else {
      yield put(getUserProfileFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getUserProfileFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onChangeProfile({ payload }) {
  yield put(changeProfileStarted());
  try {
    let response = yield changeProfileAPI(payload);
    if (response.status === "success") {
      yield put(changeProfileSuccess());
      yield put(getUserProfileRequest());
      yield put(
        openSuccessModalRequest({ desc: "مشخصات فردی شما با موفقیت ذخیره شد." })
      );
    } else {
      yield put(changeProfileFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(changeProfileFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onChangeProfilePicture({ payload }) {
  yield put(changeProfilePictureStarted());
  try {
    let response = yield changeProfilePictureAPI(payload);
    if (response.status === "success") {
      yield put(
        changeProfilePictureSuccess({
          profileSrc: response.data,
        })
      );
    } else {
      yield put(changeProfilePictureFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(changeProfilePictureFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onChangeUserAddress({ payload }) {
  yield put(changeUserAddressStarted());
  try {
    let response = yield changeUserAddressAPI(payload);
    if (response.status === "success") {
      yield put(changeUserAddressSuccess());
      yield put(getUserProfileRequest());
      yield put(
        openSuccessModalRequest({ desc: "مشخصات آدرس شما با موفقیت ذخیره شد." })
      );
    } else {
      yield put(changeUserAddressFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(changeUserAddressFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onChangeUserBankAccount({ payload }) {
  yield put(changeUserBankAccountStarted());
  try {
    let response = yield changeUserBankAccountAPI(payload);
    if (response.status === "success") {
      yield put(changeUserBankAccountSuccess());
      yield put(getUserProfileRequest());
      yield put(
        openSuccessModalRequest({
          desc: "مشخصات حساب بانکی شما با موفقیت ذخیره شد.",
        })
      );
    } else {
      yield put(changeUserBankAccountFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(changeUserBankAccountFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onGeAnnouncements({ payload }) {
  yield put(getAnnouncementsStarted());
  try {
    let response = yield getAnnouncementsAPI(payload);
    if (response.status === "success") {
      yield put(
        getAnnouncementsSuccess({ announcements: response.data.announcements })
      );
    } else {
      yield put(getAnnouncementsFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(getAnnouncementsFailure({ error: error.message }));
  }
}
// --------------------------------------------------------------------------------
function* onSeenAnnouncement({ payload }) {
  yield put(seenAnnouncementStarted());
  try {
    let response = yield seenAnnouncementAPI(payload);
    if (response.status === "success") {
      yield put(seenAnnouncementSuccess());
      yield put(getAnnouncementsRequest());
    } else {
      yield put(seenAnnouncementFailure({ error: response.errorMessage }));
    }
  } catch (error) {
    yield put(seenAnnouncementFailure({ error: error.message }));
  }
}
// ================================================================================
function* profileSaga() {
  yield takeLatest(GET_USER_PROFILE_REQUEST, onGetUserProfile);
  yield takeLatest(CHANGE_PROFILE_REQUEST, onChangeProfile);
  yield takeLatest(CHANGE_PROFILE_PICTURE_REQUEST, onChangeProfilePicture);
  yield takeLatest(CHANGE_USER_ADDRESS_REQUEST, onChangeUserAddress);
  yield takeLatest(CHANGE_USER_BANK_ACCOUNT_REQUEST, onChangeUserBankAccount);
  yield takeLatest(GET_ANNOUNCEMENTS_REQUEST, onGeAnnouncements);
  yield takeLatest(SEEN_ANNOUNCEMENT_REQUEST, onSeenAnnouncement);
}

export default profileSaga;
