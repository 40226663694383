import axiosMiddleware from ".";
// --------------------------------------------------------------------------------
export const getUserProfileAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube/profile",
    body: data,
  });
// --------------------------------------------------------------------------------
export const changeProfileAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube/identity-number",
    body: data,
  });
// --------------------------------------------------------------------------------
export const changeProfilePictureAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube/profile-picture",
    body: data,
    config: {
      "Content-Type": "multipart/form-data",
    },
  });
// --------------------------------------------------------------------------------
export const changeUserAddressAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube/address",
    body: data,
  });
// --------------------------------------------------------------------------------
export const seenAnnouncementAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "announcement/set",
    body: data,
  });
// --------------------------------------------------------------------------------
export const changeUserBankAccountAPI = (data) =>
  axiosMiddleware({
    method: "post",
    path: "youtube/bank-account",
    body: data,
  });
