import styled from "@emotion/styled";
import MuiSwitch from "@mui/material/Switch";

const pxToRem = (px, oneRemPx = 17) => `${px / oneRemPx}rem`;

const borderWidth = 3;
const width = pxToRem(56);
const height = pxToRem(34);
const size = pxToRem(22);
const gap = (34 - 22) / 2;
const Switch = styled((props) => (
  <MuiSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    
  />
))(({ theme }) => ({
  width,
  height,
  padding: 0,
  margin: theme.spacing(1),
  // overflow: "unset",
  "& .MuiSwitch-switchBase": {
    padding: pxToRem(gap),
    "&.Mui-checked": {
      color: "#fff",
      // transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
      "& + .MuiSwitch-track ": {
        backgroundColor: theme.palette.success.main,
        opacity: 1,
        borderWidth: 0,
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: "#fff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    backgroundColor: theme.palette.grey[400],
    width: size,
    height: size,
  },
  "& .MuiSwitch-track": {
    borderRadius: 40,
    border: `solid ${theme.palette.grey[400]}`,
    borderWidth,
    backgroundColor: "transparent",
    opacity: 1,
    boxSizing: "border-box",
  },
}));

export default Switch;
