import { Fragment, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import FormGenerator from "../../../../components/FormGenerator";
import { addressForm, bankAccountForm, specificationsForm } from "./formItems";
import { useDispatch, useSelector } from "react-redux";
import {
  changeProfileRequest,
  changeUserAddressRequest,
  changeUserBankAccountRequest,
  getUserProfileRequest,
} from "../../../../redux/actions/Profile";
import {
  CHANGE_PROFILE_STARTED,
  CHANGE_USER_ADDRESS_STARTED,
  CHANGE_USER_BANK_ACCOUNT_STARTED,
} from "../../../../redux/types";
import TabBar from "../../../../components/TabBar";
import { renderStatus } from "../../../../utils/helpers";
// --------------------------------------------------------------------------------
const Profile = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.profileReducer);
  const { rType } = useSelector((state) => state.profileReducer);
  const [activeTab, setActiveTab] = useState(1);
  const loading =
    rType === CHANGE_PROFILE_STARTED ||
    rType === CHANGE_USER_ADDRESS_STARTED ||
    rType === CHANGE_USER_BANK_ACCOUNT_STARTED;

  const tabs = [
    {
      text: (
        <Fragment>
          مشخصات فردی&ensp;
          {userData?.identity_attachment &&
            renderStatus({
              status: userData?.identity_status,
              size: "small",
            })}
        </Fragment>
      ),
      value: 1,
    },
    {
      text: (
        <Fragment>
          آدرس&ensp;
          {userData?.state &&
            renderStatus({
              status: userData?.address_status,
              size: "small",
            })}
        </Fragment>
      ),
      value: 2,
    },
    {
      text: (
        <Fragment>
          حساب بانکی&ensp;
          {userData?.iban &&
            renderStatus({
              status: userData?.account_status,
              size: "small",
            })}
        </Fragment>
      ),
      value: 3,
    },
  ];

  useEffect(() => {
    dispatch(getUserProfileRequest());
  }, []);

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const renderTabs = (activeTab) => {
    switch (activeTab) {
      case 1: {
        let disabled =
          userData?.identity_status === "Approved" ||
          (userData?.identity_attachment &&
            userData?.identity_status === "Pending");
        let formItems = specificationsForm.map((obj) => ({
          ...obj,
          items: obj.items.map((item) => ({
            ...item,
            disabled:
              item.name === "identity_attachment" ||
              item.name === "national_code"
                ? disabled
                : true,
          })),
        }));
        return (
          <>
            <Grid item xs={12} md={8}>
              <Typography sx={{ mb: 5 }} textAlign="center" color="error">
                {!disabled && userData?.identity_comment}
              </Typography>
            </Grid>
            <FormGenerator
              formItems={formItems}
              initialValues={{
                first_name: userData?.first_name,
                last_name: userData?.last_name,
                email: userData?.email,
                mobile: userData?.mobile,
                national_code: userData?.national_code,
                identity_attachment: userData?.identity_attachment
                  ? userData?.identity_attachment
                  : null,
              }}
              onSubmit={(values) => {
                let formData = new FormData();

                formData.append("national_code", values.national_code);
                formData.append(
                  "identity_attachment",
                  values.identity_attachment
                );

                dispatch(changeProfileRequest(formData));
              }}
              loading={loading}
            />
          </>
        );
      }
      case 2: {
        let disabled =
          userData?.address_status === "Approved" ||
          userData?.address_status === "Pending";
        let formItems = addressForm.map((obj) => ({
          ...obj,
          items: obj.items.map((item) => ({ ...item, disabled })),
        }));
        return (
          <>
            <Grid item xs={12} md={8}>
              <Typography sx={{ mb: 5 }} textAlign="center" color="error">
                {!disabled && userData?.address_comment}
              </Typography>
            </Grid>
            <FormGenerator
              formItems={formItems}
              initialValues={{
                state: userData?.state,
                city: userData?.city,
                postal_code: userData?.postal_code,
                address: userData?.address,
              }}
              btnText="ذخیره اطلاعات"
              onSubmit={(values) => dispatch(changeUserAddressRequest(values))}
              loading={loading}
            />
          </>
        );
      }
      case 3: {
        let disabled =
          userData?.account_status === "Approved" ||
          userData?.account_status === "Pending";
        let formItems = bankAccountForm.map((obj) => ({
          ...obj,
          items: obj.items.map((item) => ({ ...item, disabled })),
        }));
        return (
          <>
            <Grid item xs={12} md={8}>
              <Typography sx={{ mb: 5 }} textAlign="center" color="error">
                {!disabled && userData?.account_comment}
              </Typography>
            </Grid>
            <FormGenerator
              formItems={formItems}
              initialValues={{
                bank_branch: userData?.bank_branch,
                card_number: userData?.card_number,
                iban: userData.iban?.replace("IR", ""),
              }}
              btnText="ذخیره اطلاعات"
              onSubmit={(values) =>
                dispatch(
                  changeUserBankAccountRequest({
                    ...values,
                    iban: "IR" + values.iban,
                  })
                )
              }
              loading={loading}
            />
          </>
        );
      }
    }
  };

  return (
    <Fragment>
      <Box paddingY={5} marginBottom={3}>
        <Typography variant="h4" textAlign="center">
          احراز هویت آفلاین (24 ساعت)
        </Typography>
      </Box>
      <Grid container justifyContent="center" sx={{ mb: 5 }}>
        <Grid item xs={12} md={8}>
          <TabBar tabs={tabs} value={activeTab} onChange={handleChangeTab} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        {renderTabs(activeTab)}
      </Grid>
    </Fragment>
  );
};

export default Profile;
