import {
  CHANGE_PROFILE_FAILURE,
  CHANGE_PROFILE_PICTURE_FAILURE,
  CHANGE_PROFILE_PICTURE_REQUEST,
  CHANGE_PROFILE_PICTURE_STARTED,
  CHANGE_PROFILE_PICTURE_SUCCESS,
  CHANGE_PROFILE_REQUEST,
  CHANGE_PROFILE_STARTED,
  CHANGE_PROFILE_SUCCESS,
  CHANGE_USER_ADDRESS_FAILURE,
  CHANGE_USER_ADDRESS_REQUEST,
  CHANGE_USER_ADDRESS_STARTED,
  CHANGE_USER_ADDRESS_SUCCESS,
  CHANGE_USER_BANK_ACCOUNT_FAILURE,
  CHANGE_USER_BANK_ACCOUNT_REQUEST,
  CHANGE_USER_BANK_ACCOUNT_STARTED,
  CHANGE_USER_BANK_ACCOUNT_SUCCESS,
  GET_ANNOUNCEMENTS_FAILURE,
  GET_ANNOUNCEMENTS_REQUEST,
  GET_ANNOUNCEMENTS_STARTED,
  GET_ANNOUNCEMENTS_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_STARTED,
  GET_USER_PROFILE_SUCCESS,
  SEEN_ANNOUNCEMENT_FAILURE,
  SEEN_ANNOUNCEMENT_REQUEST,
  SEEN_ANNOUNCEMENT_STARTED,
  SEEN_ANNOUNCEMENT_SUCCESS,
} from "../types";
// --------------------------------------------------------------------------------
export const getUserProfileRequest = (data) => ({
  type: GET_USER_PROFILE_REQUEST,
  payload: data,
});

export const getUserProfileStarted = () => ({
  type: GET_USER_PROFILE_STARTED,
});

export const getUserProfileSuccess = (data) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: data,
});

export const getUserProfileFailure = (data) => ({
  type: GET_USER_PROFILE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeProfileRequest = (data) => ({
  type: CHANGE_PROFILE_REQUEST,
  payload: data,
});

export const changeProfileStarted = () => ({
  type: CHANGE_PROFILE_STARTED,
});

export const changeProfileSuccess = (data) => ({
  type: CHANGE_PROFILE_SUCCESS,
  payload: data,
});

export const changeProfileFailure = (data) => ({
  type: CHANGE_PROFILE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeProfilePictureRequest = (data) => ({
  type: CHANGE_PROFILE_PICTURE_REQUEST,
  payload: data,
});

export const changeProfilePictureStarted = () => ({
  type: CHANGE_PROFILE_PICTURE_STARTED,
});

export const changeProfilePictureSuccess = (data) => ({
  type: CHANGE_PROFILE_PICTURE_SUCCESS,
  payload: data,
});

export const changeProfilePictureFailure = (data) => ({
  type: CHANGE_PROFILE_PICTURE_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeUserAddressRequest = (data) => ({
  type: CHANGE_USER_ADDRESS_REQUEST,
  payload: data,
});

export const changeUserAddressStarted = () => ({
  type: CHANGE_USER_ADDRESS_STARTED,
});

export const changeUserAddressSuccess = (data) => ({
  type: CHANGE_USER_ADDRESS_SUCCESS,
  payload: data,
});

export const changeUserAddressFailure = (data) => ({
  type: CHANGE_USER_ADDRESS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const changeUserBankAccountRequest = (data) => ({
  type: CHANGE_USER_BANK_ACCOUNT_REQUEST,
  payload: data,
});

export const changeUserBankAccountStarted = () => ({
  type: CHANGE_USER_BANK_ACCOUNT_STARTED,
});

export const changeUserBankAccountSuccess = (data) => ({
  type: CHANGE_USER_BANK_ACCOUNT_SUCCESS,
  payload: data,
});

export const changeUserBankAccountFailure = (data) => ({
  type: CHANGE_USER_BANK_ACCOUNT_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const getAnnouncementsRequest = (data) => ({
  type: GET_ANNOUNCEMENTS_REQUEST,
  payload: data,
});

export const getAnnouncementsStarted = () => ({
  type: GET_ANNOUNCEMENTS_STARTED,
});

export const getAnnouncementsSuccess = (data) => ({
  type: GET_ANNOUNCEMENTS_SUCCESS,
  payload: data,
});

export const getAnnouncementsFailure = (data) => ({
  type: GET_ANNOUNCEMENTS_FAILURE,
  payload: data,
});
// --------------------------------------------------------------------------------
export const seenAnnouncementRequest = (data) => ({
  type: SEEN_ANNOUNCEMENT_REQUEST,
  payload: data,
});

export const seenAnnouncementStarted = () => ({
  type: SEEN_ANNOUNCEMENT_STARTED,
});

export const seenAnnouncementSuccess = (data) => ({
  type: SEEN_ANNOUNCEMENT_SUCCESS,
  payload: data,
});

export const seenAnnouncementFailure = (data) => ({
  type: SEEN_ANNOUNCEMENT_FAILURE,
  payload: data,
});
