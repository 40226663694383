import styled from "@emotion/styled";
import {
  FormControl,
  InputBase,
  InputLabel,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import { borderColor, errorColor, inputColor } from "../../assets/colors";
import { useEffect, useState } from "react";
import { RE_ENGLISH } from "../../constants";
import { NON_ENGLISH_REGEX } from "../../utils/regex";
// --------------------------------------------------------------------------------
const TextInput = ({
  name,
  label,
  type,
  value,
  onChange,
  endAdornment,
  multiline,
  row,
  sx,
  error,
  helperText,
  pattern,
  maxLength,
  english,
  ...otherProps
}) => {
  const [direction, setDirection] = useState("ltr");
  const [helperError, setHelperError] = useState("");
  const defaultHelperText = helperError || helperText || "\u200C";

  useEffect(() => {
    if (RE_ENGLISH.test(value)) {
      setDirection("rtl");
    } else {
      setDirection("ltr");
    }
  }, [value]);

  const handleChange = (e) => {
    let value = e.target.value;
    let latinValue = value.replace(NON_ENGLISH_REGEX, "");

    let event = {
      ...e,
      target: {
        ...e.target,
        value: english ? latinValue : value,
      },
    };

    if (english && NON_ENGLISH_REGEX.test(value)) {
      setHelperError("لطفاً حروف انگلیسی وارد کنید.");
    } else {
      setHelperError("");
    }

    onChange(event);
  };

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink disabled>
        {label}
      </InputLabel>
      <BootstrapInput
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
        endAdornment={
          endAdornment && (
            <InputAdornment sx={{ p: 1 }}>{endAdornment}</InputAdornment>
          )
        }
        multiline={multiline}
        rows={row}
        sx={sx}
        error={helperError || error}
        inputProps={{ pattern, maxLength }}
        direction={direction}
        {...otherProps}
      />
      <FormHelperText error>{defaultHelperText}</FormHelperText>
    </FormControl>
  );
};

export default TextInput;

export const BootstrapInput = styled(InputBase)(({ theme, direction }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "&.MuiInputBase-root": {
    backgroundColor: inputColor,
    border: `1px solid ${inputColor}`,
    borderRadius: 4,
    transition: theme.transitions.create(["border-color"]),
    padding: 0,
    "&.Mui-focused": {
      borderColor: borderColor,
    },
    "&.Mui-error": {
      borderColor: errorColor,
    },
  },
  "& .MuiInputBase-input": {
    direction,
    borderRadius: 4,
    position: "relative",
    fontSize: 16,
    padding: "10px 12px",
    // Use the system font instead of the default Roboto font.
    // fontFamily: [
    //   "-apple-system",
    //   "BlinkMacSystemFont",
    //   '"Segoe UI"',
    //   "Roboto",
    //   '"Helvetica Neue"',
    //   "Arial",
    //   "sans-serif",
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"',
    // ].join(","),
  },
}));
