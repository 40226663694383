import { Fragment, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { logoutAdminRequest } from "../../../redux/actions/Auth";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import Button from "../../Button";
import { borderColor, paperColor } from "../../../assets/colors";
import { getAnnouncementsRequest } from "../../../redux/actions/Profile";
import { getTicketBadgeRequest } from "../../../redux/actions";
// --------------------------------------------------------------------------------
export default function PrimarySearchAppBar({
  handleDrawerToggle,
  drawerWidth,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { announcements } = useSelector((state) => state.profileReducer);
  const { ticketBadge } = useSelector((state) => state.ticketReducer);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [logoutModalShow, setLogoutModalShow] = useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const unseenAnnouncements = announcements?.filter(({ seen }) => seen === 0);

  useEffect(() => {
    dispatch(getAnnouncementsRequest());
    // dispatch(getTicketBadgeRequest());
  }, []);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogoutModalOpen = () => {
    setLogoutModalShow(true);
  };

  const handleLogoutModalClose = () => {
    setLogoutModalShow(false);
  };

  const notificationMenuId = "notification-menu";
  const renderNotificationMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      id={notificationMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        sx: {
          width: 320,
          maxWidth: "100%",
          backgroundColor: paperColor,
          borderRadius: "10px",
          p: 2,
        },
      }}
    >
      <Box sx={{ borderBottom: `1px solid ${borderColor}`, py: "6px" }}>
        اطلاعیه‌ها
      </Box>
      {unseenAnnouncements?.map((announcement) => (
        <MenuItem
          sx={{ borderBottom: `1px solid ${borderColor}`, p: 2 }}
          onClick={handleMenuClose}
        >
          <Typography variant="body2" noWrap>
            {announcement.title_fa}
          </Typography>
          <Typography variant="body2" noWrap>
            {announcement.title_fa}
          </Typography>
        </MenuItem>
      ))}
      <Box sx={{ px: 5, py: 2 }} disableRipple>
        <Button
          text="مشاهده"
          onClick={() => {
            navigate("/announcements");
            setAnchorEl(null);
          }}
        />
      </Box>
    </Menu>
  );

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      // open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem onClick={() => navigate("tickets")}>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={ticketBadge} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>تیکت‌ها</p>
      </MenuItem> */}
      <MenuItem onClick={() => navigate("/announcement")}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={unseenAnnouncements?.length} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>اطلاعیه‌ها</p>
      </MenuItem>
      <MenuItem onClick={handleLogoutModalOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          // aria-controls="primary-search-account-menu"
          // aria-haspopup="true"
          color="inherit"
        >
          <LogoutIcon />
        </IconButton>
        <p>خروج</p>
      </MenuItem>
    </Menu>
  );

  const renderLogoutModal = (
    <Dialog
      open={logoutModalShow}
      onClose={handleLogoutModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: { borderRadius: "10px" },
      }}
      fullWidth
    >
      <DialogContent sx={{ py: 4 }}>
        <DialogContentText textAlign="center">
          آیا می‌خواهید خارج شوید؟
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 4 }}>
        <Button text="بله" onClick={() => dispatch(logoutAdminRequest())} />
        <Button
          text="خیر"
          variant="outlined"
          color="secondary"
          onClick={handleLogoutModalClose}
          autoFocus
        />
      </DialogActions>
    </Dialog>
  );

  return (
    <Fragment>
      <AppBar
        elevation={0}
        sx={{
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { lg: `${drawerWidth}px` },
        }}
        position="absolute"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            MUI
          </Typography> */}
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <Box sx={{ display: { xs: "none", md: "flex", marginLeft: "auto" } }}>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              sx={{ marginX: 1 }}
              aria-controls={notificationMenuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <Badge badgeContent={unseenAnnouncements?.length} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            {/* <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              onClick={() => navigate("tickets")}
              sx={{ marginX: 1 }}
            >
              <Badge badgeContent={ticketBadge} color="error">
                <MailIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              size="large"
              // edge="end"
              aria-label="account of current user"
              // aria-controls={menuId}
              // aria-haspopup="true"
              onClick={handleLogoutModalOpen}
              color="inherit"
              sx={{ marginX: 1 }}
            >
              <LogoutIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none", marginLeft: "auto" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderNotificationMenu}
      {renderLogoutModal}
    </Fragment>
  );
}
