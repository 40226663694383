export const formItems = [
  {
    container: {
      item: true,
      xs: 12,
      md: 10,
      container: true,
    },
    items: [
      {
        name: "channel_name",
        type: "text",
        label: "نام کانال",
        required: true,
      },
      {
        name: "channel_link",
        type: "url",
        label: "آدرس کانال",
        required: true,
      },
      {
        name: "channel_content",
        type: "text",
        label: "موضوع کانال",
        required: true,
      },
      {
        name: "channel_logo",
        type: "file",
        label: "بارگذاری لوگو کانال",
      },
    ],
  },
];
