import { Container, Grid, Link, Typography } from "@mui/material";
import { formItems } from "./formItems";
import { useDispatch, useSelector } from "react-redux";
import { LoginIcon, YektuberIcon } from "../../../../assets/SVGs";
import { REGISTER_USER_STARTED } from "../../../../redux/types";
import FormGenerator from "../../../../components/FormGenerator";
import { registerUserRequest } from "../../../../redux/actions";
import { useParams } from "react-router-dom";
// --------------------------------------------------------------------------------
const Register = () => {
  const dispatch = useDispatch();
  const { referralCode } = useParams();

  const { rType } = useSelector((state) => state.authReducer);
  const loading = rType === REGISTER_USER_STARTED;

  return (
    <Container sx={{ height: "100vh", display: "flex", alignItems: "center" }}>
      <Grid container sx={{ m: "auto" }}>
        <Grid
          container
          item
          sx={{ justifyContent: { xs: "center", md: "flex-end" }, my: 2 }}
        >
          <Grid item>
            <YektuberIcon width={110} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={5} sx={{ textAlign: "center" }}>
            <FormGenerator
              initialValues={{ promotion_code: referralCode }}
              formItems={formItems}
              onSubmit={(values) => {
                dispatch(registerUserRequest({ ...values, yektuber: 1 }));
              }}
              loading={loading}
            />
            {/* <Grid item sx={{ mx: 10 }}>
              <Button text="ثبت نام با گوگل" color="secondary" />
            </Grid> */}
            {/* {"children" in formItems &&
              formItems?.children({ onClick: (form) => setFormItems(form) })} */}
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 5,
              pl: { md: 5 },
            }}
          >
            <Grid item xs={8} md={12}>
              <LoginIcon />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Register;
