import * as React from "react";
import TextField from "@mui/material/TextField";
// import AdapterJalali from "@date-io/date-fns-jalali";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, FormControl, InputAdornment, InputLabel } from "@mui/material";
import { BootstrapInput } from "../TextInput";

const DatePicker = ({ label, value, onChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl variant="standard">
        <InputLabel id="demo-customized-select-label" shrink disabled>
          {label}
        </InputLabel>
        <MuiDatePicker
          value={value}
          onChange={onChange}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <BootstrapInput
              ref={inputRef}
              {...inputProps}
              endAdornment={
                <InputAdornment position="start">
                  {InputProps?.endAdornment}
                </InputAdornment>
              }
            />
          )}
          autoFocus
          inputFormat="YYYY-MM-DD"
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default DatePicker;
