import { Grid, Link, Typography } from "@mui/material";
import { history } from "../../../../redux/store";
// --------------------------------------------------------------------------------
export const formItems = [
  {
    container: {
      item: true,
      xs: 12,
      sm: 10,
      container: true,
    },
    items: [
      {
        type: "typography",
        text: "ثبت نام",
      },
      {
        name: "first_name",
        type: "text",
        label: "نام",
        required: true,
        english: true,
      },
      {
        name: "last_name",
        type: "text",
        label: "نام خانوادگی",
        required: true,
        english: true,
      },
      {
        name: "email",
        type: "text",
        label: "ایمیل",
        required: true,
      },
      {
        name: "password",
        type: "password",
        label: "کلمه عبور",
        required: true,
      },
      {
        name: "promotion_code",
        type: "text",
        label: "کد معرف (اختیاری)",
      },
      {
        name: "agreement",
        type: "checkbox",
        label: (
          <Typography variant="body2">
            <Link href="#">توافق نامه کاربری یک تیوبر</Link> را مطالعه کرده و با
            آن موافقم.
          </Typography>
        ),
        required: true,
      },
      {
        name: "recaptcha",
        type: "recaptcha",
        required: true,
      },
      {
        type: "submit",
        text: "ثبت نام",
      },
      {
        type: "custom",
        render: () => (
          <Grid item xs={12}>
            <Typography>
              حساب کاربری دارید؟{" "}
              <Link href="#" onClick={() => history.push("/login")}>
                وارد شوید
              </Link>
            </Typography>
          </Grid>
        ),
      },
    ],
  },
];
