import {
  forgotEmailRequest,
  forgotPasswordRequest,
  forgotTokenRequest,
} from "../../../../redux/actions";
import { store } from "../../../../redux/store";
// --------------------------------------------------------------------------------
export const forgotEmailForm = [
  {
    container: {
      item: true,
      xs: 12,
      sm: 10,
      container: true,
    },
    items: [
      {
        type: "typography",
        text: "فراموشی کلمه عبور",
      },
      {
        name: "email",
        type: "text",
        label: "ایمیل",
        required: true,
      },
      {},
    ],
    onSubmit: (values) => {
      store.dispatch(forgotEmailRequest(values));
      forgotEmailForm[0].values = values;
    },
  },
];

export const forgotTokenForm = [
  {
    container: {
      item: true,
      xs: 12,
      sm: 10,
      container: true,
    },
    items: [
      {
        type: "typography",
        text: "فراموشی کلمه عبور",
      },
      {
        name: "token",
        type: "text",
        label: "کد تایید",
        required: true,
      },
      {},
    ],
    onSubmit: (values) => {
      let previousValues = forgotEmailForm[0].values;
      let currentValues = { ...values, ...previousValues };
      store.dispatch(forgotTokenRequest(currentValues));
      forgotTokenForm[0].values = currentValues;
    },
  },
];

export const forgotPasswordForm = [
  {
    container: {
      item: true,
      xs: 12,
      sm: 10,
      container: true,
    },
    items: [
      {
        type: "typography",
        text: "فراموشی کلمه عبور",
      },
      {
        name: "new_password",
        type: "password",
        label: "کلمه عبور جدید",
        required: true,
      },
      {
        name: "confirm_new_password",
        type: "password",
        label: "تکرار کلمه عبور جدید",
        required: true,
      },
      {},
    ],
    onSubmit: (values) => {
      let previousValues = forgotTokenForm[0].values;
      let currentValues = { ...values, ...previousValues };
      store.dispatch(forgotPasswordRequest(currentValues));
    },
  },
];
